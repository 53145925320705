// import axios from 'axios';
import { axiosApi as axios, apiUrl } from "../constants/index";

//  /api/1.0/clientBicomServers
export const get_license_informations = (server_id) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/getLicenseInfo`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ header: "Erreur!", message: error.message });
      });
  });
};

//  /api/1.0/clientBicomServers
export const get_client_bicom_servers = (partner_id) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { partner_id },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/clientBicomServers`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ header: "Erreur!", message: error.message });
      });
  });
};

export const get_bicom_server_packages = (server_id) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/packages`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ header: "Erreur!", message: error.message });
      });
  });
};

export const create_tenant_on_bicom_server = (server_id, name, packageId, extensions) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id, name, packageId, extensions},
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/createTenant`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error)
        reject({ header: "Erreur!", message: error.message });
      });
  });
}

export const get_available_uad = (server_id) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/getUadList`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error)
        reject({ header: "Erreur!", message: error.message });
      });
  });
}

export const enable_uad = (server_id, uad_id) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id, uad_id },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/enableUad`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error)
        reject({ header: "Erreur!", message: error.message });
      });
  });
}

export const create_extensions = (server_id, extension) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: {
      server_id,
      name: extension.name,
      email: extension.email,
      uad: extension.uad,
      extension: extension.extension,
      pin: extension.pin,
      mac: extension.mac
    },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/createExtensions`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ header: "Erreur!", message: error.message });
      });
  });
}

export const get_tenant_configuration = (server_id) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/getTenantConfiguration`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ header: "Erreur!", message: error.message });
      });
  });
}