import React, { Component, Fragment } from 'react';
import {
    UI_BUTTON_SIZE
} from '../../../constants/index';

import { Icon, Divider, Button, Input, Grid, Segment, Loader, Dimmer, Progress } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ClientActions from '../../../actions/client';
import { makeCancelable } from '../../../utils';
import LounaTable from '../../../Components/LounaTable'


const StatItem = ({val})=>(
    <Progress size='small' value={val.current} total={val.total}
            style={{ margin: '0' }}
            color={val.current < val.total ? 'green' : 'blue'}
           // warning={(val.current > 0 && val.current >= val.total)}
            progress='ratio'>
    </Progress>
);


class Licenses  extends Component {
    constructor(props) {
        super(props);
        this.state = { filter: '', licences: [], loading: true, disabled_refresh: false };
        this.getlicenses = makeCancelable(this.props.client.get_licenses(this.props.server_id));

        this.getlicenses.promise.then(payload => {
            console.log(payload);
            this.setState({licences: payload, loading: false });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, loading: false });
        });

    };


    componentWillUnmount() {
        if (this.getlicenses) this.getlicenses.cancel();
    }


    handleRefresh = () => {
        this.setState({ disabled_refresh:true });
        this.getlicenses.promise.then(payload => {
            this.setState({licences: payload, disabled_refresh: false });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, disabled_refresh: false });
        });
    }

    handleSearch = (e) => {
        this.setState({ filter : e.target.value });
    }

    handleLicense = (value) => (license) => {
        const lic = license[value];
        if (lic) {
            if (value === "date") {
                return (<Fragment>{lic}</Fragment>);
            }
            return (<Fragment>
                        <StatItem val={lic} />
                    </Fragment>
            );
            // const c = (lic.current >= lic.total) ? 'red': 'green';
            // return (<Label circular color={c} style={{maxWidth: '90px'}} size='small'>{lic.current} / {lic.total}</Label>)
        }
        else
            return null;    
    }

    render() {
        const {loading} = this.state;
        const wd = 150;
        let filtred = this.state.licences;/*[
            {date: '2019-12-31', office: {current: 0, total: 0}, business: { current: 13, total: 20 }, agent:{ current: 9, total: 10 }, superviseur: { current: 10, total: 10 }, mobile:{ current: 11, total: 40 }, crm:{ current: 9, total: 20 }, 'call popup': { current: 7, total: 99 } },
            {date: '2020-01-01', office: {current: 1, total: 19}, business: { current: 13, total: 20 }, agent:{ current: 9, total: 10 }, superviseur: { current: 10, total: 10 }, mobile:{ current: 11, total: 40 }, crm:{ current: 9, total: 20 }, 'call popup': { current: 7, total: 99 } },
        ];*/
        return (
            <Segment basic style={{ minHeight: '50vh' }}>
                {loading ? <Dimmer inverted active>
                <Loader indeterminate>Chargement des licences...</Loader>
                </Dimmer> : null}

                <Grid stackable columns={2}>
                    <Grid.Column textAlign="left">
                        <Button size={UI_BUTTON_SIZE} color="blue" disabled={this.state.disabled_refresh} loading={this.state.disabled_refresh} onClick={this.handleRefresh}><Icon name='sync' />Actualiser</Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <Input icon='search' placeholder='Recherche...' size='small' style={{ marginRight: "10px" }} onChange={ this.handleSearch }></Input>
                    </Grid.Column>
                </Grid>
                <Divider />

                <LounaTable
                            data={filtred}
                            columns={[
                                {
                                    Header: "Date",
                                    id: "date",
                                    accessor: this.handleLicense("date"),
                                    sortabe: true
                                }, {
                                    Header: "Office",
                                    id: "office",
                                    accessor: this.handleLicense("office"),
                                    sortable: false,
                                    maxWidth: wd,
                                }, {
                                    Header: "Business",
                                    id: "business",
                                    accessor: this.handleLicense("business"),
                                    sortable: false,
                                    maxWidth: wd,
                                }, {
                                    Header: "Agent",
                                    id: "agent",
                                    accessor: this.handleLicense("agent"),
                                    sortable: false,
                                    maxWidth: wd,
                                }, {
                                    Header: "Superviseur",
                                    id: "superviseur",
                                    accessor: this.handleLicense("superviseur"),
                                    sortable: false,
                                    maxWidth: wd,
                                }, {
                                    Header: "Mobile",
                                    id: "mobile",
                                    accessor: this.handleLicense("mobile"),
                                    sortable: false,
                                    maxWidth: wd,
                                }, {
                                    Header: "CRM",
                                    id: "crm",
                                    accessor: this.handleLicense("crm"),
                                    sortable: false,
                                    maxWidth: wd,
                                }, {
                                    Header: "Call Popup",
                                    id: "call_popup",
                                    accessor: this.handleLicense("call popup"),
                                    sortable: false,
                                    maxWidth: wd,
                                }
                            ]}
                            getTrProps={(state, rowInfo) => {
                                let style = {};
                                if (rowInfo) {
                                    if (rowInfo.original.constructor === 'none') {
                                        return {
                                            style, className:"disabled"
                                        };
                                    }
                                    style = { cursor: 'pointer' }
                                    return {
                                        style,
                                        onClick: (e) => this.handleDisplay(e, rowInfo.original)
                                    };
                                }
                                return {};
                            }}

                        />
            </Segment>
        );
    };
}


const mapDispatchToProps = dispatch => ({
    client: bindActionCreators(ClientActions, dispatch),
});

export default connect(
    null,
    mapDispatchToProps
)(Licenses);