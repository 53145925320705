import { connect } from 'react-redux';
import SignInForm from '../Components/SignInForm';
import { authenticate, me, change_password, signin_reset, riddle, reset_password} from  '../actions/users'


function mapStateToProps(state, ownProps) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps, {authenticate, me, change_password, signin_reset, riddle, reset_password})(SignInForm);