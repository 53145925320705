import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

import './css/SubscriptionSelect.css';


class SubscriptionSelect extends Component {

  render() {
    const {subscriptions, onSelect, selectedPackage} = this.props;

    const subscriptionsList = Object.entries(subscriptions);

    return <div className="SubscriptionContainer">
      {subscriptionsList.map(subscription =>
        <div className="Subscription">
          <div className="SubscriptionHeader">
            <span className="SubscriptionTitle">{subscription[1].name}</span>
            <span>Contenant</span>
          </div>
          <div className="SubscriptionDetails">
            <span>{subscription[1].ext} extensions</span>
            <span>{subscription[1].voicemail} messages vocaux</span>
            <span>{subscription[1].queues} files d'attente</span>
            <span>{subscription[1].ivr} SVI</span>
            <span>{subscription[1].cf} conférences</span>
            <span>{subscription[1].rgroups} groupes d'extension</span>
            <span>{subscription[1].hot_desking} hot desking</span>
          </div>
          <button className={selectedPackage === subscription[0] ? 'SubscriptionSelect selected' : 'SubscriptionSelect'} onClick={(e) => onSelect(subscription[0])}>{selectedPackage === subscription[0] ? <Icon name='check' /> : 'Sélectionner'}</button>
        </div>
      )}
    </div>
  }

}
  
export default SubscriptionSelect;