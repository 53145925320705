// import axios from 'axios';
import { axiosApi as axios, apiUrl } from "../constants/index";

//  /api/1.0/getUadAlertConf
export const get_uad_alert = (server_id, percentage, email) => {
  let sessionId = localStorage.getItem("x-token");
  let config = {
    params: { server_id, percentage, email },
    headers: { Authorization: "Bearer " + sessionId },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/api/1.0/getUadAlertConf`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({ header: "Erreur!", message: error.message });
      });
  });
};

//  /api/1.0/createUadAlertConf
export const create_uad_alert = (server_id, percentage, email) => {
    let sessionId = localStorage.getItem("x-token");
    let config = {
      params: { server_id, percentage, email },
      headers: { Authorization: "Bearer " + sessionId },
    };
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/api/1.0/createUadAlertConf`, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject({ header: "Erreur!", message: error.message });
        });
    });
};

//  /api/1.0/updateUadAlertConf
export const update_uad_alert = (server_id, percentage, email) => {
    let sessionId = localStorage.getItem("x-token");
    let config = {
      params: { server_id, percentage, email },
      headers: { Authorization: "Bearer " + sessionId },
    };
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${apiUrl}/api/1.0/updateUadAlertConf`, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject({ header: "Erreur!", message: error.message });
        });
    });
};
