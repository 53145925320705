import React, { Component, Fragment } from 'react';
import { Menu, Image, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserActions from '../actions/users';
import ModalChangePassword from './forms/modalChangePassword';

import { makeCancelable } from '../utils';

class AppMenu extends Component {
    state = {
        changePassword : false,
        referer: null
    };

    componentWillUnmount() {
        if (this.change_password)
            this.change_password.cancel();
    };

    handleChangePassword = () => {
        this.setState({changePassword:true});
    };

    handleChangePasswordResult = (password) => {
        if (password) {
            this.change_password = makeCancelable(this.props.users.simple_change_password(password));
            this.change_password.promise.then(response => {
            });
        }
        this.setState({changePassword:false});
    };

    displayModal = () => {
        if (this.state.changePassword)
            return <ModalChangePassword onResult={this.handleChangePasswordResult}></ModalChangePassword>
    };

    render() {
        const {user} = this.props.user;
        return (
            <Menu fixed='top' >
                {this.state.referer && <Redirect to={this.state.referer}/>}
                {this.displayModal()}
                <div style={{marginTop:10,marginBottom:10,cursor:'pointer'}} onClick={()=>this.setState({referer:'/'})}>
                    <Image size='small' src='/images/logo.png'/>
                </div>
                <Menu.Menu position='right'>
                    <Dropdown pointing='top right' item icon="user outline" text={user.login + '\u00a0\u00a0'}>
                        <Dropdown.Menu >
                            <Fragment><Dropdown.Item icon='shield alternate' text='Changer le mot de passe' onClick={this.handleChangePassword} /><Dropdown.Divider /></Fragment>
                            <Dropdown.Item icon='sign-out' text='Quitter' onClick={ ()=>this.props.logout()} />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        )
    }
};

const mapDispatchToProps = dispatch => ({
    users: bindActionCreators(UserActions, dispatch)
});

export default connect(
    null,
    mapDispatchToProps,
)(AppMenu);
