import React, { Component, Fragment } from 'react'
import { Message, Table, Button, Icon, Segment, Popup, Input, Divider, Modal, Header } from 'semantic-ui-react';
import LounaTable from '../../../Components/LounaTable';
import ModalConfigurationTouch from './modalCreateConfigurationTouch'

import { connect } from 'react-redux';
import { get_phone_key, get_keysconfiguration, link_phone_key, unlink_phone_key, post_keysconfiguration } from '../../../actions/keys';
import { makeCancelable } from '../../../utils';

import {
    UI_BUTTON_SIZE
} from '../../../constants/index';


/*
const key_type = [
    { id: 1, label: 'BLF' },
    { id: 2, label: 'SpeedDial' },
    { id: 3, label: 'DTMF' },
    { id: 4, label: 'VoiceMail' },
    { id: 5, label: 'Transfert' },
    { id: 6, label: 'Line' },
    { id: 7, label: 'Prefix' },
];
*/
class PhoneConfigCard extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: true, error: null, modal:false, modalContent:{}, modalConfirm:false }
        this.get_key_conf(this.props.conf);
    }

    componentDidUpdate(prevProps) {
        if (this.props.conf.id !== prevProps.conf.id) {
            this.get_key_conf(this.props.conf);
        }
    }
    componentWillUnmount() {
        if (this.getkeyconf) this.getkeyconf.cancel();
        if (this.linkphonekey) this.linkphonekey.cancel();
        if (this.unlinkphonekey) this.unlinkphonekey.cancel();
        if (this.postcustomkey) this.postcustomkey.cancel();
    }

    get_key_conf = (conf) => {
        this.getkeyconf = makeCancelable(this.props.get_phone_key(this.props.phone_id, conf.index, conf.type));
        this.getkeyconf.promise.then(payload => {
            //console.log("phone_key", payload);
            this.setState({ loading: false, error: null, key_conf: payload });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ is_valid: false, loading: false });
        });
    }

    handleSelected = original => event => {
        const { conf } = this.props;
        this.linkphonekey = makeCancelable(this.props.link_phone_key(this.props.phone_id, conf.index, conf.type, original.key_id));
        this.linkphonekey.promise.then(payload => {
            this.get_key_conf(this.props.conf);
        }).catch(error => {
            if (error.isCanceled) return;
        });
    }

    handleUnlinkKey = event => {
        const { conf } = this.props;
        this.unlinkphonekey = makeCancelable(this.props.unlink_phone_key(this.props.phone_id, conf.index, conf.type));
        this.unlinkphonekey.promise.then(payload => {
            this.get_key_conf(this.props.conf);
        }).catch(error => {
            if (error.isCanceled) return;
        });
    };

    handleEditConfiguration = e => {
        if (this.state.key_conf) {
            let modalContent = this.state.key_conf;
            modalContent.header = "\u00a0\u00a0Modifier la Configuration de touche \"" + modalContent.name + "\"";
            this.setState({modal:true,modalContent:modalContent});
        }
    };

    handleAddConfiguration = e => {
        this.setState({modal:true,modalContent:{}});
    };

    handleResultModal = (r) => {
        if (r && r.name) {
            this.postcustomkey = makeCancelable(this.props.post_keysconfiguration(this.props.client_id, r.name, r.label, r.type, r.value, r.line, r.extension === "none" ? null : r.extension, r.key_id === 0 ? null : r.key_id));
            this.postcustomkey.promise.then(response => {
                if (response && response.key_id) {
                    const { conf } = this.props;
                    this.linkphonekey = makeCancelable(this.props.link_phone_key(this.props.phone_id, conf.index, conf.type, response.key_id));
                    this.linkphonekey.promise.then(payload => {
                        this.get_key_conf(this.props.conf);
                    }).catch(error => {
                        if (error.isCanceled) return;
                    });
                } else this.get_key_conf(this.props.conf);
            });
        }
        this.setState({modal:false});
    };

    handleCloseConfirme = (e, confirm) => {
        if (confirm === true)
            this.handleUnlinkKey(e);
        this.setState({modalConfirm:false});
    };

    displayConfirmation = () => {
        if (this.state.modalConfirm)
            return (
            <Modal open={true} onClose={(e)=> this.handleCloseConfirme(e, false)}
                size='mini'>
                <Header as='h4' icon={'trash alternate'} content={'Confirmation de suppression'} />
                <Modal.Content>
                    Etes-vous sûr de vouloir désassocier cette touche ?
                 </Modal.Content>
                <Modal.Actions>
                    <Button size={UI_BUTTON_SIZE} icon="remove" content="Non" color="red" inverted onClick={(e)=> this.handleCloseConfirme(e, false)}></Button>
                    <Button size={UI_BUTTON_SIZE} icon='checkmark' content="Effacer" color='green' onClick={(e)=> this.handleCloseConfirme(e, true)}></Button>
                </Modal.Actions>
            </Modal>
            );
    };

    // deselectionne la touche
    handleUneSlect = () => {
        if (this.props.onBack)
            this.props.onBack();
    }

    render() {
        const {key_list} = this.props;
        const isLinked = (this.state.key_conf && this.state.key_conf.key_id);
        return (
            <Segment color="black" style={{ minHeight: '38vh' }} textAlign="left">
                    {this.displayConfirmation()}
                    {this.state.modal && <ModalConfigurationTouch server_id={this.props.server_id} onResult={this.handleResultModal} value={this.state.modalContent}></ModalConfigurationTouch>}
                    <Button style={{ margin: 10 }} size={UI_BUTTON_SIZE} onClick={this.handleUneSlect} icon='chevron left' ></Button>
                    <Button color="blue" style={{margin:10}} size={UI_BUTTON_SIZE} onClick={this.handleAddConfiguration}><Icon name='add'/>Nouvelle Configuration</Button>
                    <TestITem key_list={key_list} onSelect={this.handleSelected}></TestITem>
                    {isLinked && <Button style={{margin:10}} size={UI_BUTTON_SIZE} color='blue' onClick={this.handleEditConfiguration}><Icon name='edit'/>Editer</Button>}
                    {isLinked && <Button color="orange" style={{margin:10}} size={UI_BUTTON_SIZE} disabled={!isLinked} onClick={()=>this.setState({modalConfirm:true})}><Icon name='unlink'/>Désassocier</Button>}                {isLinked ? <Fragment>
                    <KeyInfo meta={this.state.key_conf}></KeyInfo>
                </Fragment> : <Fragment>
                    <Message size='tiny' icon info>
                        <Icon size="tiny" name='info' />
                        <Message.Content>
                        <Message.Header>Cette touche n'est pas configurée.</Message.Header>
                    </Message.Content>
                </Message>
                </Fragment>}
            </Segment>
        )
    }
}



const KeyInfo = ({ meta }) => (
    <Table celled striped definition>
        <Table.Body>
            <Table.Row><Table.Cell collapsing>Nom</Table.Cell><Table.Cell>{meta.name}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell collapsing>Type</Table.Cell><Table.Cell>{meta.type}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell collapsing>Valeur</Table.Cell><Table.Cell>{meta.value}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell collapsing>Libéllé</Table.Cell><Table.Cell>{meta.label}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell collapsing>Extension</Table.Cell><Table.Cell>{meta.extension}</Table.Cell></Table.Row>
            <Table.Row><Table.Cell collapsing>Ligne</Table.Cell><Table.Cell>{meta.line}</Table.Cell></Table.Row>
        </Table.Body>
    </Table>
)



export default connect(
    null,
    { get_phone_key, get_keysconfiguration, link_phone_key, unlink_phone_key, post_keysconfiguration},
)(PhoneConfigCard);

class TestITem extends Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, filter:"" }
    }

    handleOpen = () => {
        this.setState({ isOpen: true });
    }


    handleSelected = original => e => {
        this.props.onSelect(original)(e);
        this.setState({ isOpen: false });
    }
    handleClose = () => {
        this.setState({ isOpen: false });
    }

    handleSearch = (e) => {
        this.setState({ filter : e.target.value });
    };

    render() {
        const { key_list } = this.props;
        const filter = this.state.filter.toLowerCase();

        const filtred = key_list.filter((item) => {
            return item.name.toLowerCase().search(filter) !== -1;
        });


        return (<Popup wide position='bottom center'
            trigger={<Button icon="linkify" color="blue" size={UI_BUTTON_SIZE} content='Associer une Configuration existante' />}
            open={this.state.isOpen}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            on='click'>
            <Fragment>
                <Input autoFocus icon='search' fluid placeholder='Recherche...' size='small' onChange={ this.handleSearch }></Input>
                <Divider fitted></Divider>
                <LounaTable data={filtred} psize={5} columns={[{
                    Header: "Type",
                    accessor: "type",
                    minWidth: 150,
                    maxWidth: 150
                }, {
                    Header: "Nom",
                    accessor: "name",
                    minWidth: 150,
                    maxWidth: 400
                }]} defaultSorted={[{
                    id: "name"
                }]} getTrProps={(state, rowInfo) => {
                    let style = {};
                    if (rowInfo) {
                        style = {
                            cursor: 'pointer'
                        };
                        return {
                            style,
                            onClick: this.handleSelected(rowInfo.original)
                        };
                    }
                    return {};
                }} />
            </Fragment>
        </Popup>);
    }
}