// import axios from 'axios';
import {
    axiosApi as axios,
    apiUrl,
    DASHBOARD_GETSTATS,
    DASHBOARD_GETSTATS_SUCCESS,
    DASHBOARD_GETSTATS_FAILED,

} from '../constants/index'

export const dashboard_getstats = () => ({ type: DASHBOARD_GETSTATS });
export const dashboard_getstats_success = (data) => ({ type: DASHBOARD_GETSTATS_SUCCESS, payload: data });
export const dashboard_getstats_failed = (error) => ({ type: DASHBOARD_GETSTATS_FAILED, payload: error });

// /api/1.0/dashboard
export const get_dashboard = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/dashboard`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};

