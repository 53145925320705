import React, { Component } from "react";


export default class ImageMap extends Component {

    constructor(props) {
        super(props);
        this.selected = -1;
        this.state = {drawArea: false, scale: 1};
        let absPos = { position: 'absolute', top: 0, left: 0 };
        this.styles = {
            container: { position: 'relative' },
            canvas: { ...absPos, pointerEvents: 'none', zIndex: 2, padding: 0, border: 0 },
            img: { ...absPos, zIndex: 1, userSelect: 'none', maxWidth: 500 },
            map: (props.onClick && { cursor: 'pointer' } )|| undefined
        };
    }

    hoverOn = (area, idx, event) => {
        const shape = event.target.getAttribute('shape');
        this['draw' + shape](event.target.getAttribute('coords').split(','), 'rgba(255, 255, 255, 0.5)');
        //console.log('HoverON  => ' + area._id );
    }
    hoverOff = (area, idx, event) => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.renderPrefilledAreas(this.state.scale);
        //console.log('HoverOFF => ' + area._id);
    }
    handleclick = (area, i, e) => {
        if (this.props.onClick) {
            e.stopPropagation();
            e.preventDefault();

            if (this.selected === i)
                return;

            this.selected = i;
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.renderPrefilledAreas(this.state.scale);

            this.props.onClick(area, i, e);
        }
    }

    initialize = () => {

        this.canvas.width = this.img.width;
        this.canvas.height = this.img.height;
        this.container.style.width = this.img.clientWidth + 'px';
        this.container.style.height = this.img.clientHeight + 'px';

        this.ctx = this.canvas.getContext('2d');
        this.ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        this.ctx.lineWidth = 1;

        const scale = this.img.width / this.img.naturalWidth;
        this.setState({ drawArea: true, scale: this.img.width / this.img.naturalWidth});
        this.renderPrefilledAreas(scale);
    }


    drawrect(coords, fillColor) {
        let [left, top, right, bot] = coords;
        this.ctx.fillStyle = fillColor;
        this.ctx.strokeRect(left, top, right - left, bot - top);
        this.ctx.fillRect(left, top, right - left, bot - top);
        this.ctx.restore();
    }

    drawcircle(coords, fillColor) {
        this.ctx.fillStyle = fillColor;
        this.ctx.beginPath();
        this.ctx.arc(coords[0], coords[1], coords[2], 0, 2 * Math.PI);
        this.ctx.closePath();
        this.ctx.stroke();
        this.ctx.fill();
        this.ctx.restore();
    }

    renderPrefilledAreas(scale) {
        const { areas } = this.props.map;

        areas.map((area, idx) => {
            if (this.selected===idx)
                this['draw' + area.shape](area.coords.map(i => i * scale), area.color);
            return null;
        });
    }

    componentDidUpdate(prevProps) {

        if (this.props.asKey !== prevProps.asKey && this.props.asKey === false) {
            this.selected = -1;
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.renderPrefilledAreas(this.state.scale);
        }

        if( this.props.selected !== prevProps.selected && this.props.selected >= 0){
            this.selected = this.props.selected;
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.renderPrefilledAreas(this.state.scale);
        }
    }

    renderAreas= () => {
        const { areas } = this.props.map;
        const {scale}  = this.state;
        return areas.map((area, idx) => {
            return (<area key={area._id} shape={area.shape} coords={area.coords.map(i=> i * scale).join(',')}
                onMouseEnter={this.hoverOn.bind(this, area, idx)}
                onMouseLeave={this.hoverOff.bind(this, area, idx)}
                onClick={this.handleclick.bind(this, area, idx)} alt='' href='#' />)
        });
    }

    render() {
        //console.log("popo")
        return (
            <div style={this.styles.container} ref={node => this.container = node}>
                <img
                    ref={node => this.img = node}
                    onLoad={this.initialize}
                    style={this.styles.img}
                    src={this.props.src}
                    useMap='#map-phone'
                    alt=''/>
                <canvas ref={node => this.canvas = node} style={this.styles.canvas} />
                {this.state.drawArea && <map style={this.styles.map} name='map-phone' alt='#'>{this.renderAreas()}</map>}
            </div>
        )
    }
}