import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, Header
} from 'semantic-ui-react'
import { UI_BUTTON_SIZE } from '../constants/index';



export default class ListRemoveConfirm extends Component {

    state = { modalOpen: false }


    handleOpen = event => { event.stopPropagation(); this.setState({ modalOpen: true }) }
    handleClose = event => { event.stopPropagation(); this.setState({ modalOpen: false }) }
    handleValidate = (e) => {
        e.stopPropagation();
        this.setState({ modalOpen: false });
        this.props.onValidate(e);
    }

    render() {
        const { header, icon, label } = this.props;
        const { modalOpen } = this.state;

        return (
            <Modal trigger={<Button icon={icon} color='red' inverted size='mini'
                onClick={this.handleOpen } />} open={modalOpen}
                onClose={this.handleClose}
                size='mini'>
                <Header as='h4' icon={icon} content={header} />
                <Modal.Content>
                    Etes-vous sûr de vouloir supprimer {label.length ? <b>'{label}'</b> : null} ?
                 </Modal.Content>
                <Modal.Actions>
                    <Button size={UI_BUTTON_SIZE} icon="remove" content="Non" color="red" inverted onClick={this.handleClose}></Button>
                    <Button size={UI_BUTTON_SIZE} icon='checkmark' content="Effacer" color='green' onClick={this.handleValidate}></Button>
                </Modal.Actions>
            </Modal>

        )
    }
}

ListRemoveConfirm.propTypes = {
    icon: PropTypes.string,
    header: PropTypes.string.isRequired,
    onValidate: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

ListRemoveConfirm.defaultProps = {
    onValidate: () => { },
    icon: 'trash'

};