import React, { Component } from "react";
import ImageMap from '../../../Components/ImageMap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ClientActions from '../../../actions/client';
import * as BicomActions from '../../../actions/bicom';
import { get_keysconfiguration } from '../../../actions/keys';

import PhoneConfigCard from './PhoneConfigCard';
import PhoneResumeCard from './PhoneResumeCard';
import { Grid, Message, Container, Icon, Dimmer, Loader, Divider, Button, Segment } from 'semantic-ui-react';
import { makeCancelable } from '../../../utils';

import {
    UI_BUTTON_SIZE
} from '../../../constants/index';

class PhoneConfigurationView extends Component {

    constructor(props) {
        super(props);
        this.state = { disabled_deploy: false, selected: -1, loading: true, error: null, is_valid: false }

        this.getphone = makeCancelable(this.props.client.get_phone(this.props.phone_id));
        this.getphone.promise.then(payload => {
            this.props.client.client_select_phone(payload.phone_id, payload.phone_name);
            this.setState({phone: payload});
            this.get_phone_conf(payload);
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, loading: false });
        });

        this.get_key_list();
    }

    get_phone_conf = (phone) => {
        this.getphonemeta = makeCancelable(this.props.client.get_phone_meta(phone.constructor, phone.name, phone.config));
        this.getphonemeta.promise.then(payload => {

            let areas = payload.keys.map(key => {
                key.type = key.type.replace(/\s/g, '');
                key.index = key.id.replace(/\D/g, '');
//                console.log(key.type);
                if (key.position.type === 'rect')
                    return { key, color: "#399de8ad", _id: key.id, shape: 'rect', coords: [key.position.left, key.position.top, key.position.left + key.position.width, key.position.top + key.position.height] };
                if (key.position.type === 'circle')
                    return { key, color: "#399de8ad", _id: key.id, shape: 'circle', coords: [key.position.left + 11, key.position.top + 11, key.position.width / 2] };
                return false;
            });
            this.setState({ meta: payload, areas, is_valid: true, loading: false, error: null });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ is_valid: false, loading: false });
        });
    }

    get_key_list = () => {
        this.getkeylist = makeCancelable(this.props.keys.get_keysconfiguration(this.props.server_id));
        this.getkeylist.promise.then(payload => {
            this.setState({ loading2: false, error2: null, keys: payload });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ loading2: false, error2: error });
        });
    }


    componentWillUnmount() {
        this.props.client.client_select_phone(0, "");
        if (this.getphone) this.getphone.cancel();
        if (this.getphonemeta) this.getphonemeta.cancel();
        if (this.send_conf) this.send_conf.cancel();
        if (this.check_conf) this.check_conf.cancel();
        if (this.timer) clearInterval(this.timer);
    }

    clicked = (area) =>{
        this.setState({key: area.key});
    }

    handleDeploy = () => {
        this.setState({disabled_deploy:true});
        this.send_conf = makeCancelable(this.props.bicom.send_conf(this.props.server_id, this.props.phone_id));
        this.send_conf.promise.then( response => {
            if (response && response.status === 'running') {
                this.timer = setInterval(()=> {
                    this.check_conf = makeCancelable(this.props.bicom.check_conf(this.props.server_id));
                    this.check_conf.promise.then( response => {
                        if (response && response.status !== 'running') {
                            clearInterval(this.timer);
                            this.setState({disabled_deploy:false});
                        }
                    });
                }, 500);
            } else {
                this.setState({disabled_deploy:false});
            }
        });
    };

    onSyncContacts = () => {
        window.location = 'https://maconfig.tel:1443/customlogin/' + this.state.phone.single_use + '?redirect=' + encodeURIComponent(window.location);
    };

    onDesyncContacts = () => {
        window.location = 'https://maconfig.tel:1443/disconnect?single_use=' + this.state.phone.single_use + '&redirect=' + encodeURIComponent(window.location);
    };

    render() {
        if (this.state.loading)
            return (<Dimmer active inverted><Loader inverted>Loading</Loader></Dimmer>);

        if (!this.state.is_valid)
            return (<Container textAlign="left">
                <Message icon info>
                    <Icon size="tiny" name='info' />
                    <Message.Content>
                        <Message.Header>Ce téléphone n'est pas supporté.</Message.Header>
                    </Message.Content>
                </Message>
            </Container>
            )

        const map = {areas: this.state.areas};
        const {phone, meta, key, keys} = this.state;
        if (!phone.constructor || !meta.keys) // todo: error
            return null;

        const URL = ['/models', phone.constructor, phone.name, meta.image].join('/');
        return (
            <Container>
                <Container fluid textAlign='left'>
                    <Button disabled={this.state.disabled_deploy} loading={this.state.disabled_deploy} style={{marginRight:10}} size={UI_BUTTON_SIZE} color="blue" onClick={()=> this.handleDeploy()}> <Icon name='play' />Deployer</Button>
                    {this.state.phone.book_id > 0 ? <Button size={UI_BUTTON_SIZE} inverted color='orange' onClick={()=>this.onDesyncContacts()}><Icon name='at' />Désynchroniser mes contacts</Button> : <Button size={UI_BUTTON_SIZE} color='teal' onClick={()=> this.onSyncContacts()}><Icon name='at' />Synchroniser mes contacts</Button>}
                </Container>
                <Divider/>
                <Grid stackable columns='equal'>
                    <Grid.Column style={{ overflowX: 'auto'}}>
                        <center><Segment compact><ImageMap src={URL} map={map} onClick={this.clicked} selected={this.state.selected} asKey={key ? true : false}></ImageMap></Segment></center>
                    </Grid.Column>
                    <Grid.Column>
                        {key ? <PhoneConfigCard onBack={() => this.setState({ key: undefined, selected: -1 })} client_id={this.props.client_id} server_id={this.props.server_id} phone_id={this.props.phone_id} conf={key} key_list={keys}></PhoneConfigCard>
                            : <PhoneResumeCard phone_id={this.props.phone_id} onClick={this.clicked} model={meta} onSelected={(idx) => this.setState({selected: idx}) } ></PhoneResumeCard>}
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}



const mapStateToProps = state => ({
    phone: state.phone
});

const mapDispatchToProps = dispatch => ({
    client: bindActionCreators(ClientActions, dispatch),
    bicom: bindActionCreators(BicomActions, dispatch),
    keys: bindActionCreators({get_keysconfiguration}, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PhoneConfigurationView);

