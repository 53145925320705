import React, { Component } from 'react'
import { Table, Segment, Dimmer, Loader } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { get_phone_keys } from '../../../actions/keys';
import { makeCancelable } from '../../../utils';

const style = {cursor: "pointer"}

class PhoneResumeCard extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, error: null, key_list: [] }

        this.getkeys = makeCancelable(this.props.get_phone_keys(this.props.phone_id));
        this.getkeys.promise.then(payload => {
            this.setState({ loading: false, error: null, key_list: payload });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, loading: false });
        });
    }

    componentWillUnmount() {
        if (this.getkeys) this.getkeys.cancel();
    }
    handleClickTouch = (item, idx) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onSelected(idx);
        this.props.onClick({key: item});
    }

    renderKeys = () => {
        const linekeys = [];
        const memorykeys = [];
        const { model } = this.props;
        this.state.key_list.forEach(item => {
            if (item.keytype === 'linekey') linekeys[item.position] = item;
            if (item.keytype === 'memorykey') memorykeys[item.position] = item;
        });

        return model.keys.map((item, idx) => {
            let conf = null;
            if (item.type === 'linekey') conf = linekeys[item.index];
            if (item.type === 'memorykey') conf = memorykeys[item.index];

            if (conf) {
                return (<Table.Row style={style} onClick={this.handleClickTouch(item, idx)} key={conf.key_id}>
                    <Table.Cell>{item.id}</Table.Cell>
                    <Table.Cell>{conf.name}</Table.Cell>
                    <Table.Cell>{conf.type}</Table.Cell>
                    <Table.Cell>{conf.label}</Table.Cell>
                    <Table.Cell textAlign='right' disabled={conf.type === 'BLF'}>{conf.value}</Table.Cell>
                    <Table.Cell textAlign='right' disabled={conf.type !== 'BLF'}>{conf.extension}</Table.Cell>
                    <Table.Cell>{conf.line}</Table.Cell>
                </Table.Row>);
                }
            return (<Table.Row style={style} key={item.id} onClick={this.handleClickTouch(item, idx)}>
                <Table.Cell>{item.id}</Table.Cell>
                <Table.Cell active></Table.Cell>
                <Table.Cell active></Table.Cell>
                <Table.Cell active></Table.Cell>
                <Table.Cell active></Table.Cell>
                <Table.Cell active></Table.Cell>
                <Table.Cell active></Table.Cell>
            </Table.Row>);
        });
    }
    render() {

        const { loading } = this.state;
        if (loading)
            return (<Dimmer active inverted><Loader indeterminate>Recherche...</Loader></Dimmer>);

        return (
            <Segment color="black" style={{ minHeight: '38vh', overflowX:'auto'}}>
                <Table celled definition collapsing selectable fixed singleLine unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2}></Table.HeaderCell>
                            <Table.HeaderCell width={4}>Nom</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                            <Table.HeaderCell width={4}>Libéllé</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Valeur</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Extension</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Ligne</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderKeys()}
                    </Table.Body>
                </Table>


            </Segment>
        )
    }
}

export default connect(
    null,
    { get_phone_keys },
)(PhoneResumeCard);
