import React, { Component } from 'react';
import './css/Tip.css'

class Tip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.children
    };
  }
  
  render() {
    return (
      <h1 className='Tip'>{this.state.text}</h1>
    );
  }
}
  
export default Tip;