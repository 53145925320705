import React, { Component } from 'react';
import './css/Button.css'

class Button extends Component {

  render() {
    const {disabled, onClick, buttonName, className} = this.props;

    return (
        <button className={'NextButton ' + className} onClick={onClick} disabled={disabled}>{buttonName}</button>
    );
  }

}

export default Button;