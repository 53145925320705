// import axios from 'axios';
import {
    axiosApi as axios,
    apiUrl,
    ADMIN_GET_PARTNER,
    ADMIN_GET_PARTNER_SUCCESS,
    ADMIN_GET_PARTNER_FAILED,
} from '../constants/index'




export const admin_getlist = () => ({ type: ADMIN_GET_PARTNER });
export const admin_getlist_success = (data) => ({ type: ADMIN_GET_PARTNER_SUCCESS, payload: data });
export const admin_getlist_failed = (error) => ({ type: ADMIN_GET_PARTNER_FAILED, payload: error });

// /api/1.0/partnerslist
export const get_partner_list = () => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { headers: { 'Authorization': "Bearer " + sessionId } };

        dispatch(admin_getlist());
        return axios.get(`${apiUrl}/api/1.0/partners`, config)
            .then(response => {
                dispatch(admin_getlist_success(response.data));
                // userSchema.isValid(response.data).then((valid) => {
                //     valid ? dispatch(signin_success(response.data)) : dispatch(signin_failed({ header: "Erreur!", message: 'Réponse invalide' }));
                // });
            })
            .catch(error => {
                if (!error.response)
                    dispatch(admin_getlist_failed({ header: "Erreur!", message: error.message }));
                /*
                else if (error.response.status === 401)
                    dispatch(signin_failed({ header: "L'authentification a échoué!", message: error.response.data.log }));
                else
                    dispatch(signin_failed({ header: "L'authentification a échoué!", message: error.response.statusText }));
                */
            })
    };
};
