// import axios from 'axios';
import {
    axiosApi as axios,
    apiUrl,
    SERVER_SET_CONF,
    SERVER_SET_CONF_SUCCESS,
    SERVER_SET_CONF_FAILED,

    TENANT_GET_CONF,
    TENANT_GET_CONF_SUCCESS,
    TENANT_GET_CONF_FAILED,

} from '../constants/index';




export const server_set_conf = () => ({ type: SERVER_SET_CONF });
export const server_set_conf_success = (data) => ({ type: SERVER_SET_CONF_SUCCESS, payload: data });
export const server_set_conf_failed = (error) => ({ type: SERVER_SET_CONF_FAILED, payload: error });


//  /api/1.0/configurationserver
export const post_server_configuration = (server_id, user_id, bicom, tenant, uad) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: { user_id: user_id ? user_id : undefined, server_id,
                        name: bicom.name, server_url:bicom.server_url, server_login: bicom.server_login, server_mdp: bicom.server_mdp,
                        tenant_name: tenant.tenant_name, tenant_prefix: tenant.tenant_prefix, tenant_id: tenant.tenant_id,
                        uad_login: uad.uad_login,  uad_password: uad.uad_password },
            headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.post(`${apiUrl}/api/1.0/configurationserver`,null,config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};




export const tenant_get_conf = () => ({ type: TENANT_GET_CONF });
export const tenant_get_conf_success = (data) => ({ type: TENANT_GET_CONF_SUCCESS, payload: data });
export const tenant_get_conf_failed = (error) => ({ type: TENANT_GET_CONF_FAILED, payload: error });

//  /api/1.0/tenant
export const get_server_tenant = (server, login, password) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server, login, password }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/tenant`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};



//  /api/1.0/configurationserver
export const remove_server = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: { server_id }, headers: { 'Authorization': "Bearer " + sessionId } };
        return axios.delete(`${apiUrl}/api/1.0/configurationserver`, config)
            .then(response => {
                // dispatch(tenant_get_conf_success(response.data));
            })
            .catch(error => {
                // if (!error.response)
                //     dispatch(tenant_get_conf_failed({ header: "Erreur!", message: error.message }));
            })
    };
};