import React from 'react'

import { Container, Message, Icon } from 'semantic-ui-react';

const ErrorMessage = ({error})=> (
    <Container textAlign="left">
        <Message icon negative hidden={!error}>
            <Icon size="tiny" name='warning' />
            <Message.Content>
                <Message.Header>{error ? error.header : ''}</Message.Header>
                <p>{error && error.message}</p>
            </Message.Content>
        </Message>
    </Container>
);


export default ErrorMessage;