import React, { Component } from 'react';
import './css/Select.css';

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onSelect: props.onSelect
    };
  }
  
  render() {
    return (
        <select className='Select' onChange={this.state.onSelect}>
            {this.props.children}
        </select>
    );
  }
}

export default Select;