import React, { Component } from 'react';
import ReactTable from "react-table";
import Pagination from '../Components/Pagination';



class LounaTable extends Component{
    render() {
        const psize = this.props.psize || 25;
        return (
    <ReactTable
        {...this.props}
        className='-striped -highlight'
        PaginationComponent={Pagination}
        defaultPageSize={psize}
        minRows={0}
        //
        getTdProps={(state, rowInfo, column, instance) => {
            let style = {};
            if (column.id === 'action')
                return style;
            if (column.id === 'status')
                // eslint-disable-next-line
                style = { textAlign: 'center', textAlign: '-webkit-center', ...style }
            style = { display: 'flex', flexDirection: 'column', justifyContent: 'center', ...style}

            return { style };
        }}
        // Text
        previousText='⟨'
        nextText='⟩'
        loadingText='Chargement...'
        noDataText='Aucun élément'
        pageText='Page'
        ofText='sur'
        rowsText='éléments'
    />
        )}
}

export default LounaTable;