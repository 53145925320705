import {
    LOGOUT_USER,
    CLIENT_SELECT_PHONE,
    CLIENT_GET_PHONE_LIST,
    CLIENT_GET_PHONE_LIST_SUCCESS,
    CLIENT_GET_PHONE_LIST_FAILED,
} from '../constants/index';


const INITIAL_STATE = {
    error: null, loading: false, data: [], phone_id: 0, phone_name: ''
};

export default function register(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGOUT_USER:
        case CLIENT_GET_PHONE_LIST:
            return { ...state, loading: true, error: null, data: [], phone_id: 0, phone_name:'' };
        case CLIENT_GET_PHONE_LIST_SUCCESS:
            action.payload.forEach(phone => phone.tags = JSON.parse(phone.tags) || []);
            return { ...state, loading: false, error: null, data: action.payload, phone_id: 0, phone_name:'' };
        case CLIENT_GET_PHONE_LIST_FAILED:
            return { ...state, loading: false, error: { header: action.payload.header, message: action.payload.message }, data: [], phone_id: 0, phone_name:'' };
        case CLIENT_SELECT_PHONE:
            return { ...state,  phone_id: action.phone_id, phone_name: action.phone_name};
        default:
            return state;
    }
}
