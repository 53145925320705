import React, { Component } from "react";
import { Dimmer, Loader, Icon } from "semantic-ui-react";
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';

import "./css/CreateTenant.css";

import Question from "../../Components/wizard/Question";
import Tip from "../../Components/wizard/Tip";
import TextBox from "../../Components/wizard/TextBox";
import SubscriptionSelect from "../../Components/wizard/SubscriptionSelect";
import Button from "../../Components/wizard/Button";
import TenantSummary from "../../Components/wizard/TenantSummary";
import Select from "../../Components/wizard/Select";
import SelectOption from "../../Components/wizard/SelectOption";

import { makeCancelable } from '../../utils';

import {
  get_license_informations,
  get_client_bicom_servers,
  get_bicom_server_packages,
  create_tenant_on_bicom_server
} from "../../actions/wizard";

class CreateTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      step: 1,
      licenseMaxExtensions: 0,
      bicomServers: [],
      selectedBicomServer: null,
      packages: {},
      tenant: {
        name: "",
        package: null,
        packageConfiguration: {
          name: "",
        },
        extensions: "",
      }
    };

    this.handleStep1Click = this.handleStep1Click.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleSubscriptionSelect = this.handleSubscriptionSelect.bind(this);
    this.handleStepEdit = this.handleStepEdit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBicomServerSelect = this.handleBicomServerSelect.bind(this);
  }

  componentDidMount() {
    this.get_user_servers = makeCancelable(
      get_client_bicom_servers(this.props.partner_id)
    );

    this.get_user_servers.promise
      .then((response) => {
        this.setState({
          bicomServers: response,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleStep1Click(e) {
    console.log(e.target.value);
  }

  handleBicomServerSelect(e) {
    this.setState({
      selectedBicomServer: e.target.value,
    });

    this.get_packages = makeCancelable(
      get_bicom_server_packages(e.target.value)
    );

    this.get_packages.promise
      .then((packages) => {
        this.setState({ packages: packages });
      })
      .catch((error) => {
        console.error(error);
      });

    this.get_license_info = makeCancelable(
      get_license_informations(e.target.value)
    );

    this.get_license_info.promise
      .then(licenseInfo => {
        this.setState({ licenseMaxExtensions: licenseInfo.Extensions });
      })
      .catch(e => {
        console.error(e);
      })
  }

  handleChange(e) {
    e.persist();

    this.setState((previousState) => {
      return {
        tenant: {
          ...previousState.tenant,
          [e.target.name]: e.target.value,
        },
      };
    });
  }

  handleClick(e) {
    this.setState({
      step: this.state.step + 1,
    });
  }

  handleClickBack(e) {
    this.setState({
      step: this.state.step - 1,
    });
  }

  isValidName = () => {
    if (this.state.tenant.name === "") {
      return false;
    }
    return true;
  };

  isValidSelection = () => {
    if (this.state.tenant.package === null) {
      return false;
    }
    return true;
  };

  isValidNumber = () => {
    const extensions = Number.parseInt(this.state.tenant.extensions, 10);
    if (extensions > 0 && extensions <= 9999 && Number.isInteger(extensions)) {
      return true;
    }
    return false;
  };

  isValidServer = () => {
    if (
      this.state.selectedBicomServer === null ||
      this.state.selectedBicomServer === ""
    ) {
      return false;
    }
    return true;
  };

  handleSubscriptionSelect(selectedPackageId) {
    if (this.state.tenant.package === selectedPackageId) {
      this.setState((previousState) => ({
        tenant: {
          ...previousState.tenant,
          package: null,
          packageConfiguration: {},
        },
      }));
    } else {
      this.setState((previousState) => ({
        tenant: {
          ...previousState.tenant,
          package: selectedPackageId,
          packageConfiguration: this.state.packages[selectedPackageId],
        },
      }));
    }
  }

  handleStepEdit(step) {
    this.setState({
      step: step,
    });
  }

  handleSubmit(e) {
    this.setState({
      loading: true,
    });

    this.create_tenant = makeCancelable(
      create_tenant_on_bicom_server(
        this.state.selectedBicomServer,
        this.state.tenant.name,
        this.state.tenant.package,
        this.state.tenant.extensions
      )
    );

    this.create_tenant.promise
      .then((response) => {
        this.setState({
          loading: false,
        });

        if (response.server_id) {
          this.setState({
            step: 6,
            serverId: response.server_id
          });
        }
      })
      .catch((error) => {
        console.log(error);        
        
        this.setState({
          loading: false,
        });

        toast({
            type: 'error',
            icon: 'close',
            title: 'Erreur',
            description: 'Une erreur est survenue lors de la création du tenant.',
            time: 5000
        });
      });
  }

  componentWillUnmount() {
    if (this.get_user_servers) this.get_user_servers.cancel();
    if (this.get_license_info) this.get_license_info.cancel();
    if (this.get_packages) this.get_packages.cancel();
    if (this.create_tenant) this.create_tenant.cancel();
  }

  render() {
    return (
      <div className="Wizard">
        <SemanticToastContainer position="top-right" />
        {this.state.loading && (
          <Dimmer active inverted>
            <Loader>Création du tenant, cela devrait prendre environ une minute.</Loader>
          </Dimmer>
        )}
        <button className="leaveButton" onClick={e => this.props.history.push("/partner/" + this.props.partner_id)}><Icon name='close' /></button>
        <div
          className="step"
          style={{ display: this.state.step === 1 ? "block" : "none" }}
        >
          <Question>Sur quel serveur souhaitez-vous créer le tenant ?</Question>
          <Tip>Vous ne pouvez sélectionner qu'un serveur Multi-tenant</Tip>
          <Select onSelect={this.handleBicomServerSelect}>
            <SelectOption key="-1" value="" label="-Sélectionnez un serveur-" />
            {this.state.bicomServers.map((bicomServer, key) => (
              <SelectOption
                key={key}
                value={bicomServer.server_id}
                label={bicomServer.server_url}
              />
            ))}
          </Select>
          <br />
          <Button
            onClick={this.handleClick}
            disabled={!this.isValidServer()}
            buttonName="Suivant"
          />
        </div>
        <div
          className="step"
          style={{ display: this.state.step === 2 ? "block" : "none" }}
        >
          <Question>Comment s'appelle la société du client ?</Question>
          <Tip>Ce sera le nom du tenant</Tip>
          <TextBox
            name="name"
            placeholder="Nom de la société"
            onChange={this.handleChange}
          />
          <br />
          <Button
            className="back"
            onClick={this.handleClickBack}
            disabled={false}
            buttonName={<Icon name="angle left" />}
          />
          <Button
            onClick={this.handleClick}
            disabled={!this.isValidName()}
            buttonName="Suivant"
          />
        </div>
        <div
          className="step"
          style={{ display: this.state.step === 3 ? "block" : "none" }}
        >
          <Question>Quel type d'abonnement désirez vous ?</Question>
          {this.state.licenseMaxExtensions !== 0 &&
            <Tip>Vous pouvez en créer de nouveaux depuis votre interface PBXware. <br/> Attention, votre licence est limitée à {this.state.licenseMaxExtensions} extensions.</Tip>
          }
          {this.state.packages.error == null ? 
            <SubscriptionSelect
              subscriptions={this.state.packages}
              onSelect={this.handleSubscriptionSelect}
              selectedPackage={this.state.tenant.package}
            /> :
            <h1 style={{color: '#E3564F'}}>Nous n'avez aucun package. Veuillez en créer au préalable sur votre IPBX.</h1>
          }
          <br />
          <Button
            className="back"
            onClick={this.handleClickBack}
            disabled={false}
            buttonName={<Icon name="angle left" />}
          />
          <Button
            onClick={this.handleClick}
            disabled={!this.isValidSelection()}
            buttonName="Suivant"
          />
        </div>
        <div
          className="step"
          style={{ display: this.state.step === 4 ? "block" : "none" }}
        >
          <Question>Combien de postes seront installés ?</Question>
          <Tip>Vous pourrez modifier cette information ultérieurement</Tip>
          <TextBox
            name="extensions"
            placeholder="Nombre de postes"
            onChange={this.handleChange}
          />
          <br />
          <Button
            className="back"
            onClick={this.handleClickBack}
            disabled={false}
            buttonName={<Icon name="angle left" />}
          />
          <Button
            onClick={this.handleClick}
            disabled={!this.isValidNumber()}
            buttonName="Suivant"
          />
        </div>
        <div
          className="step"
          style={{ display: this.state.step === 5 ? "block" : "none" }}
        >
          <Question>Voici le récapitulatif des informations du tenant</Question>
          <Tip>Vérifiez si tout vous convient</Tip>
          <TenantSummary
            tenant={this.state.tenant}
            editStep={this.handleStepEdit}
          />
          <br />
          <Button
            className="back"
            onClick={this.handleClickBack}
            disabled={false}
            buttonName={<Icon name="angle left" />}
          />
          <Button
            onClick={this.handleSubmit}
            disabled={false}
            buttonName="Valider"
          />
        </div>
        <div
          className="step"
          style={{ display: this.state.step === 6 ? "block" : "none" }}
        >
          <Question>Souhaitez vous créer les extensions maintenant ?</Question>
          <Tip>
            Pas de panique, vous aurez toujours la possibilité de le faire
            ultérieurement
          </Tip>
          <Button
            onClick={() =>
              this.props.history.push("/wizard/extensions/" + this.state.serverId)
            }
            className="yesButton"
            disabled={false}
            buttonName="Oui"
          />
          <Button
            onClick={() =>
              this.props.history.push("/partner/" + this.props.partner_id)
            }
            className="leave"
            disabled={false}
            buttonName="Non, pas maintenant"
          />
        </div>
      </div>
    );
  }
}

export default CreateTenant;
