import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

import "./css/TenantSummary.css";

class ExtensionsSummary extends Component {
  render() {
    const { extension, uadLabel, editStep } = this.props;

    return (
      <div className="Summary FullWidth">
        <span className="SummaryTitle">
            {extension.name}
            <button className="editButton" onClick={(e) => editStep(2)}>
            <Icon name="edit" />
            </button>
        </span>
        <div className="SummaryDetails">
          <div className="SummaryLabels">
            <span>Email</span>
            <span>UAD</span>
            <span>Numéro de l'extension</span>
            <span>PIN</span>
            <span>Adresse MAC</span>
          </div>
          <div className="SummaryValues">
            <span>
              {extension.email}
            </span>
            <span>
              {uadLabel}
            </span>
            <span>
              {extension.extension}
            </span>
            <span>
              {extension.pin}
            </span>
            <span>
              {extension.mac}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ExtensionsSummary;