import React, { Component } from 'react';

import { Segment, Button, Icon, Divider, Container, Grid } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as AppActions from '../../actions/admin';
import * as UserActions from '../../actions/users';

import AppHeader from '../../Components/AppHeader';
import LounaTable from '../../Components/LounaTable'
import CreateUser from './modalCreateUser';
import ListRemoveConfirm from '../../Components/ListRemoveConfirm';

import { makeCancelable } from '../../utils';

import {
    UI_BUTTON_SIZE
} from '../../constants/index';


class AdminView extends Component {

    state = {
        modal: false,
        currentUser: {role:'partner'}
    };

    componentWillUnmount() {
        if (this.post_user)
            this.post_user.cancel();
        if (this.get_partners)
            this.get_partners.cancel();
        if (this.remove_user)
            this.remove_user.cancel();
        if (this.get_user)
            this.get_user.cancel();
    };

    handleDisplay = (e, item) => {
        e.stopPropagation();
        this.props.history.push('/partner/' + item.partner_id);
    };

    handleEdit = (d) => (e) => {
        this.get_user = makeCancelable(this.props.users.get_user(d.partner_name));
        this.get_user.promise.then(response => {
            this.setState({modal:true,currentUser:{role:'partner',login:response.login,email:response.email,password:'*********',disable:response.disable,id:response.id,company:response.company}})
        });
        e.stopPropagation();
    };

    handleRemove = (d) => (e) => {
        e.stopPropagation();
        this.remove_user = makeCancelable(this.props.users.remove_user(d.partner_name));
        this.remove_user.promise.then(response => {
            this.get_partners = makeCancelable(this.props.admin.get_partner_list());
        });
    };

    handleMenuRow = (d) => {
        //console.log(d);
        return (
        <Grid stackable columns={1}>
            <Grid.Column textAlign="right">
                <Button icon='edit' color='blue' inverted size='mini' onClick={this.handleEdit(d)}></Button>
                <ListRemoveConfirm header='Confirmation de suppression' icon='trash alternate' label={''} onValidate={this.handleRemove(d)}></ListRemoveConfirm>
            </Grid.Column>
        </Grid>
        )
    }

    handleModalResult = (r) => {
        if (r) {
//            console.log(r);
            if (r.password !== '*********')
                this.post_user = makeCancelable(this.props.users.post_user(r.login, r.email, r.role, r.id, r.company, r.disable === true ? 'true' : 'false', r.password));
            else
                this.post_user = makeCancelable(this.props.users.post_user(r.login, r.email, r.role, r.id, r.company, r.disable === true ? 'true' : 'false'));
            this.post_user.promise.then(response => {
                this.get_partners = makeCancelable(this.props.admin.get_partner_list());
            });
        }
        this.setState({modal:false});
    };

    displayModal = () => {
        return this.state.modal && <CreateUser onResult={this.handleModalResult} value={this.state.currentUser}></CreateUser>
    };

    componentDidMount() {
        this.get_partners = makeCancelable(this.props.admin.get_partner_list());
    };

    render() {
        const {data} = this.props.payload;
        return (
            <Container>
                {this.displayModal()}
                <AppHeader icon="address card outline" header="Partenaires"></AppHeader>
                <div className="detailview-content container-fluid">
                {/* table */}
                    <Segment>
                        <Button size={UI_BUTTON_SIZE} primary onClick={()=>{this.setState({modal:true})}}> <Icon name='plus' />Nouveau</Button>
                        <Divider  />
                        <LounaTable
                            data={data}
                            columns={[
                                {
                                    Header: "Nom",
                                    accessor: "partner_name",
                                }, {
                                    Header: "Licences",
                                    accessor: "quota",
                                    maxWidth: 150,
                                }, {
                                    Header: "Nbr clients",
                                    accessor: "clients",
                                    maxWidth: 150,
                                }, {
                                    Header: "",
                                    id: 'action',
                                    maxWidth: 105,
                                    accessor: this.handleMenuRow
                                }
                            ]}
                            getTrProps={(state, rowInfo, column) => {
                                if (rowInfo) {
                                    return {
                                        style: {
                                            cursor: 'pointer',
                                        },
                                        onClick: (e) => this.handleDisplay(e, rowInfo.original)
                                    };
                                }
                                return {};
                            }}

                        />

                    </Segment>
                </div>
            </Container>
        );
    }
}


const mapStateToProps = state => ({
    payload: state.admin
});

const mapDispatchToProps = dispatch => ({
    admin: bindActionCreators(AppActions, dispatch),
    users: bindActionCreators(UserActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AdminView);

