import React, { Component } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { Icon } from "semantic-ui-react";
import "./css/TransferList.css";

class TransferList extends Component {

    render() {
        return (
            <DualListBox
                id="transfer"
                options={this.props.available}
                selected={this.props.selected}
                onChange={this.props.onChange}
                canFilter={true}
                filterPlaceholder="Rechercher..."
                icons={{
                moveLeft: <Icon name="arrow left" />,
                moveRight: <Icon name="arrow right" />
                }}
            />
        );
    }

}

export default TransferList;