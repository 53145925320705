import {
    LOGOUT_USER,
    ADMIN_GET_PARTNER,
    ADMIN_GET_PARTNER_SUCCESS,
    ADMIN_GET_PARTNER_FAILED,
} from '../constants/index';


const INITIAL_STATE = {
    error: null, loading: false, data: []
};

export default function register(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGOUT_USER:
        case ADMIN_GET_PARTNER:
            return { ...state, loading: true, error: null, data: [] };
        case ADMIN_GET_PARTNER_SUCCESS:
            return { ...state, loading: false, error: null, data: action.payload };
        case ADMIN_GET_PARTNER_FAILED:
            return { ...state, loading: false, error: { header: action.payload.header, message: action.payload.message }, data: [] };
        default:
            return state;
    }
}
