import React, { Component } from "react";
import { Icon } from "semantic-ui-react";

import "./css/TenantSummary.css";

class TenantSummary extends Component {
  render() {
    const { tenant, editStep } = this.props;

    return (
      <div className="Summary">
        <span className="SummaryTitle">Nouveau tenant</span>
        <div className="SummaryDetails">
          <div className="SummaryLabels">
            <span>Nom du tenant</span>
            <span>Abonnement</span>
            <span>Postes à installer</span>
          </div>
          <div className="SummaryValues">
            <span>
              {tenant.name}
              <button className="editButton" onClick={(e) => editStep(2)}>
                <Icon name="edit" />
              </button>
            </span>
            <span>
              {tenant.packageConfiguration["name"]}
              <button className="editButton" onClick={(e) => editStep(3)}>
                <Icon name="edit" />
              </button>
            </span>
            <span>
              {tenant.extensions}
              <button className="editButton" onClick={(e) => editStep(4)}>
                <Icon name="edit" />
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default TenantSummary;
