import React from 'react';
import { Form } from 'semantic-ui-react';


export const InputLine = (props) => (<Form.Group inline style={{ marginBottom: '30px' }}>
    <Form.Field width={6}>
        <label className='form-label'>{props.label}</label>
    </Form.Field>
    <Form.Field width={10}>
        <label className='form-control'>{props.value}</label>
    </Form.Field>
</Form.Group>
)