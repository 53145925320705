import React, { Component, Fragment } from 'react'

import { Modal, Button, Icon, Form, Grid, Step, Segment, List, Responsive, Dimmer, Loader } from 'semantic-ui-react';
import { UI_BUTTON_SIZE } from '../../constants/index';
import SelectTenantContainer from '../../containers/SelectTenantContainer';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormBicom, FormUAD} from '../../Components/forms/FormBicom'
import ErrorMessage from '../../Components/ErrorMessage';

import * as ClientActions from '../../actions/client';
import * as ServerActions from '../../actions/server';

import { makeCancelable } from '../../utils'
import * as Yup from 'yup';

const bicomSchema = Yup.object().shape({
    name: Yup.string().required(),
    server_url: Yup.string().url().required(),
    server_login: Yup.string().required(),
    server_mdp: Yup.string().required()
});


class CreateServerModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            doNext: false, submiting: false, error: null,
            loading: false, step: 1,
            bicom: { name: '', server_url: '', server_login: '', server_mdp: '' },
            tenant:{ tenant_name: '', tenant_prefix: '', tenant_id: '' },
            uad: { uad_login: '', uad_password: '' }
        }
        this.base = this.state;
    }

    componentWillUnmount() {
        if (this.p_post)
            this.p_post.cancel();
        if (this.sync_phone_list)
            this.sync_phone_list.cancel();
        if (this.check_sync_phone_list)
            this.check_sync_phone_list.cancel();
        if (this.timer)
            clearInterval(this.timer);
    };

    handleChange = (info, input, blur) => (event, e) => {
        switch (info){
            case 'bicom':
                const { bicom } = this.state;
                blur ? bicom[input] = event.target.value.trim() : bicom[input] = event.target.value;
                this.setState({ bicom });
                break;
            case 'tenant':
                const { tenant } = this.state;
                tenant.tenant_name = input.name;
                tenant.tenant_prefix = input.tenantcode;
                tenant.tenant_id = input.id;
                this.setState({ tenant });
                break;
            case 'uad':
                const { uad } = this.state;
                uad[input] = event.target.value;
                this.setState({ uad });
                break;
            default:
                break
        }
    }

     prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1,
            doNext: true
        })
    }

    nextStep = () => {
        const { step } = this.state
        if (step !== 4)
            this.setState({
                step : step + 1,
                doNext: false
            })
    }

    handleWaitSynchro = (server_id) => {
        this.sync_phone_list = makeCancelable(this.props.client.sync_phone_list(server_id));
        this.sync_phone_list.promise.then( response => {
            if (response && response.status === 'running') {
                this.timer = setInterval(()=> {
                    this.check_sync_phone_list = makeCancelable(this.props.client.check_sync_phone_list(server_id));
                    this.check_sync_phone_list.promise.then( response => {
                        if (response && response.status !== 'running') {
                            clearInterval(this.timer);
                            this.props.onDone();
                        }
                    }).catch(error => {
                        // error sur le check_sync_phone_list
                        clearInterval(this.timer);
                        if (error.isCanceled) return;
                        this.setState({ error });
                    });
                }, 500);
            } else {
                this.props.onDone();
            }
        }).catch(error => {
            // error sur le sync_phone_list
            if (error.isCanceled) return;
            this.setState({ error });
        });
    }

    handleSubmitConfiguration = () => {
        this.setState({submiting: true})

        const { bicom, tenant, uad } = this.state;
        this.p_post = makeCancelable(this.props.server.post_server_configuration(undefined, this.props.partner_id, bicom, tenant, uad));
        this.p_post.promise.then( payload => {
            this.handleWaitSynchro(payload.server_id);
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error });
        });
    }

    handleClose = () => {
        if (this.state.submiting && !this.state.error) return;
        this.setState(this.base);
        this.props.onClose();
    }

    handleValidateStep = status => {
        this.setState({doNext: status});
    }

    isValidForm = () => {
        if (this.state.step === 1) {
            return bicomSchema.isValidSync(this.state.bicom);
        }
        if (this.state.step === 2)
            return this.state.doNext;
        return true;
    }


    render_submit = () =>{
        if (this.state.error)
            return (<ErrorMessage error={this.state.error}></ErrorMessage>);
        return <Dimmer active inverted><Loader indeterminate>Création de l'environnement client...</Loader></Dimmer>;
    }

    render() {
        const { step, bicom, tenant, uad, submiting, error} = this.state;

        return (
            <Modal open={true} onClose={this.handleClose} centered={false} style={{userSelect: 'none'}} size='small' >
                <Modal.Header>
                    <Icon circular inverted color='blue' name='server' />
                    {'\u00a0\u00a0'}Création d'un environnement
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Grid stackable>
                            <Responsive
                                getWidth={getWidth}
                                minWidth={Responsive.onlyTablet.minWidth}
                            >
                            <Grid.Column width={2}>
                                <ModalStep value step={step} />
                            </Grid.Column>
                            <Grid.Column width={1} />
                            </Responsive>
                            <Grid.Column width={13}>
                                <Segment>
                                    <Form style={{ minHeight: 282 }} onSubmit={this.nextStep}>
                                        {submiting ? this.render_submit()
                                        :<Fragment>
                                        {step === 1 && <FormBicom handleChange={this.handleChange} values={bicom} />}
                                        {step === 2 && <SelectTenantContainer onChange={this.handleChange} bicom={bicom} values={tenant} onValidateStep={this.handleValidateStep}/>}
                                        {step === 3 && <FormUAD handleChange={this.handleChange} values={uad} />}
                                        {step === 4 && <ResumeForm bicom={bicom} uad={uad} tenant={tenant}></ResumeForm>}
                                        </Fragment>
                                        }
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {submiting ?  error && <Button size={UI_BUTTON_SIZE} onClick={this.handleClose}><Icon name='cancel' />Fermer</Button>
                    :<Fragment>
                        {step > 1 && <Button size={UI_BUTTON_SIZE} onClick={this.prevStep}><Icon name='left chevron' />Précédent</Button>}
                        {step === 4 && <Button size={UI_BUTTON_SIZE} onClick={this.handleSubmitConfiguration} positive>Valider<Icon name='triangle right' /></Button>}
                        {step !== 4 && <Button disabled={!this.isValidForm()} size={UI_BUTTON_SIZE} primary onClick={this.nextStep}>Suivant<Icon name='right chevron' /></Button>}
                    </Fragment>
                    }
                </Modal.Actions>
            </Modal>
        )
    }
}





const style={
    marginLeft: 15,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
}

const ResumeForm = ({bicom, tenant, uad}) => (
    <List relaxed>
        <List.Item>
            <List.Header>Nom serveur Bicom: </List.Header>
            <span style={style}>{bicom.name}</span>
        </List.Item>
        <List.Item>
            <List.Header>Adresse du serveur Bicom: </List.Header>
            <span style={style}>{bicom.server_url}</span>
        </List.Item>
        <List.Item>
            <List.Header>Login Bicom: </List.Header>
            <span style={style}>{bicom.server_login}</span>
        </List.Item>
        {/* <List.Item>
            <List.Header>Mot de passe Bicom: </List.Header>
            <span style={style}>******</span>
        </List.Item> */}
        {
            tenant.tenant_id.length > 0 && (
                <List.Item>
                    <List.Header>Serveur tenant: </List.Header>
                    <span style={style}>{tenant.tenant_prefix} : {tenant.tenant_name}</span>
                </List.Item>
            )
        }
        {
         uad.uad_login.length > 0 &&  (
             <Fragment>
        <List.Item>
            <List.Header>Login UAD: </List.Header>
            <span style={style}>{uad.uad_login}</span>
        </List.Item>
        {/* <List.Item>
            <List.Header>Mot de passe UAD: </List.Header>
            <span style={style}>******</span>
        </List.Item> */}
        </Fragment>
            )
        }
    </List>
)


const getWidth = () => {
    const isSSR = typeof window === 'undefined'

    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}
const ModalStep = ({step}) => (

    <Step.Group vertical size='mini'>
        <Step active={ step === 1} completed={ step > 1 }>
        {/* <Icon name='server' /> */}
        <Step.Content>
            <Step.Title>Serveur</Step.Title>
        </Step.Content>
    </Step>

    <Step active={step === 2} completed={step > 2}>
        {/* <Icon name='sitemap' /> */}
        <Step.Content>
            <Step.Title>Tenant</Step.Title>
        </Step.Content>
    </Step>

    <Step active={step === 3} completed={step > 3}>
        {/* <Icon name='tty' /> */}
        <Step.Content>
            <Step.Title>UAD   </Step.Title>
        </Step.Content>
    </Step>

    <Step active={step === 4} completed={step > 4}>
        {/* <Icon name='clipboard check' /> */}
        <Step.Content>
            <Step.Title>Validation</Step.Title>
        </Step.Content>
    </Step>
</Step.Group>
)





const mapStateToProps = state => ({
    payload: state.server
});

const mapDispatchToProps = dispatch => ({
    client: bindActionCreators(ClientActions, dispatch),
    server: bindActionCreators(ServerActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateServerModal);

