import {
    LOGOUT_USER,
    TENANT_GET_CONF,
    TENANT_GET_CONF_SUCCESS,
    TENANT_GET_CONF_FAILED,
} from '../constants/index';


const INITIAL_STATE = {
    error: null, loading: true, data: {}
};

export default function register(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGOUT_USER:
        case TENANT_GET_CONF:
            return { ...state, loading: true, error: null, data: {} };
        case TENANT_GET_CONF_SUCCESS:
            return { ...state, loading: false, error: null, data: action.payload };
        case TENANT_GET_CONF_FAILED:
            return { ...state, loading: false, error: { header: action.payload.header, message: action.payload.message }, data: {} };
        default:
            return state;
    }
}
