import React, { Component } from 'react';
import SignInFormContainer from '../containers/SignInFormContainer';


const style = {
    backgroundImage: 'url(/images/login-background.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    userSelect: "none",
};

class SignInView extends Component {
    render() {
        return (
            <div style={style}>
                <SignInFormContainer />
            </div>
        );
    }
}


export default SignInView;
