import React, { Component } from 'react';
import { Modal, Form, Icon, Input, Button } from 'semantic-ui-react';

import { UI_BUTTON_SIZE } from '../../constants/index';

class ModalChangePassword extends Component {
    state = {
        open: true,
        password1: null,
        password2: null
    };

    componentDidMount() {
    };

    checkButtonState() {
        if (this.state.password1 && this.state.password2 && this.state.password1.length >= 8 && this.state.password1 === this.state.password2)
            return false;
        return true;
    };

    handleChangePassword1 = (e, value) => {
        this.setState({password1: value.value});
    };

    handleChangePassword2 = (e, value) => {
        this.setState({password2: value.value});
    };

    render() {
        return (
            <Modal style={{ userSelect: 'none' }} size="small" centered={false} open={true} onClose={() => this.props.onResult(null)}>
                <Modal.Header>
                    <Icon name='shield' circular color='blue' inverted/>
                    {'\u00a0\u00a0'}Changement du mot de passe
                </Modal.Header>
                <Modal.Content>
                    <Form style={{marginTop: 15}}>
                        <Form.Field>
                            <label>Nouveau mot de passe</label>
                            <Input type='password' placeholder='Mot de passe minimum de 8 caractères' onChange={this.handleChangePassword1}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Confirmation</label>
                            <Input type='password' placeholder='Mot de passe minimum de 8 caractères' onChange={this.handleChangePassword2}/>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button size={UI_BUTTON_SIZE} onClick={() => this.props.onResult(null)}><Icon name='cancel'/>Annuler</Button>
                    <Button size={UI_BUTTON_SIZE} disabled={this.checkButtonState()} onClick={()=>this.props.onResult(this.state.password1)} color='blue'><Icon name='chevron down' />Enregistrer</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default ModalChangePassword;
