import {
    LOGOUT_USER,
    PARTNER_GET_CLIENTLIST,
    PARTNER_GET_CLIENTLIST_SUCCESS,
    PARTNER_GET_CLIENTLIST_FAILED,
    PARTNER_GET_CLIENTSTATS_SUCCESS,
    PARTNER_GET_CLIENTSTATS_FAILED
} from '../constants/index';


const INITIAL_STATE = {
    error: null, loading: false, data: []
};

export default function register(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGOUT_USER:
        case PARTNER_GET_CLIENTLIST:
            return { ...state, loading: true, error: null, data: [] };
        case PARTNER_GET_CLIENTLIST_SUCCESS:
            return { ...state, loading: false, error: null, data: action.payload };
        case PARTNER_GET_CLIENTLIST_FAILED:
            return { ...state, loading: false, error: { header: action.payload.header, message: action.payload.message }, data: [] };
        case PARTNER_GET_CLIENTSTATS_SUCCESS:
            return { ...state, loading: false, error: null, data: action.payload };
        case PARTNER_GET_CLIENTSTATS_FAILED:
            return { ...state, loading: false, error: { header: action.payload.header, message: action.payload.message }, data: [] };
        default:
            return state;
    }
}
