import * as Yup from 'yup';
import Location from 'react-app-location';


const wholeNbr = Yup.number().integer().positive().required();
//const login = Yup.string().oneOf(['login']).required();

const AdminViewLocation = new Location('/admin');
const PartnerViewLocation = new Location('/partner/:partner_id', { partner_id: wholeNbr });
const WizardTenantViewLocation = new Location('/wizard/tenant/:partner_id', { partner_id: wholeNbr });
const WizardExtensionsViewLocation = new Location('/wizard/extensions/:server_id', { server_id: wholeNbr });
const ClientViewLocation = new Location('/client/:client_id', { client_id: wholeNbr }, { server_id: wholeNbr });



const ClientConfigurationViewLocation = new Location('/client/:client_id/configuration', { client_id: wholeNbr }, { server_id: wholeNbr });
const ClientDashboardViewLocation = new Location('/client/:client_id/dashboard', { client_id: wholeNbr }, { server_id: wholeNbr });
const ClientLicenseViewLocation = new Location('/client/:client_id/licenses', { client_id: wholeNbr }, { server_id: wholeNbr });
const ClientUsersViewLocation = new Location('/client/:client_id/users', { client_id: wholeNbr }, { server_id: wholeNbr });
const ClientAlertViewLocation = new Location('/client/:client_id/alerts', { client_id: wholeNbr }, { server_id: wholeNbr });
const ClientPhoneListViewLocation = new Location('/client/:client_id/phones', { client_id: wholeNbr }, { server_id: wholeNbr });
const ClientPhoneViewLocation = new Location('/client/:client_id/phone', { client_id: wholeNbr }, { server_id: wholeNbr, phone_id: wholeNbr });

const SignInViewLocation = new Location('/login');

export default {
    AdminView: AdminViewLocation,
    PartnerView: PartnerViewLocation,
    WizardTenantView: WizardTenantViewLocation,
    WizardExtensionsView: WizardExtensionsViewLocation,
    ClientView: ClientViewLocation,

    ClientConfigurationView: ClientConfigurationViewLocation,
    ClientDashboardView: ClientDashboardViewLocation,
    ClientLicenseView: ClientLicenseViewLocation,
    ClientUsersView: ClientUsersViewLocation,
    ClientAlertView: ClientAlertViewLocation,
    ClientPhoneListView: ClientPhoneListViewLocation,
    ClientPhoneView: ClientPhoneViewLocation,
    SignInView: SignInViewLocation,
};