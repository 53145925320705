import React, { Component } from 'react';
import { Modal, Form, Icon, Input, Button, Select } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ClientActions from '../../../actions/client';
import { makeCancelable } from '../../../utils';

import { UI_BUTTON_SIZE } from '../../../constants/index';

class ModalCreateConfigurationTouch extends Component {
    state = {
        selectedTypes: [
            { value: 'BLF', key: 'BLF', text: 'Busy Lamp Fields (BLF)' },
            { value: 'SpeedDial', key: 'SpeedDial', text: 'Numérotation Abrégée (Speed dial)' },
            { value: 'DTMF', key: 'DTMF', text: 'DTMF' },
            { value: 'VoiceMail', key: 'VoiceMail', text: 'Messagerie vocale (VoiceMail)' },
            { value: 'Transfert', key: 'Transfert', text: 'Transfert' },
            { value: 'Forward', key: 'Forward', text: 'Forward' },
            { value: 'Line', key: 'Line', text: 'Ligne' },
            { value: 'Prefix', key: 'Prefix', text: 'Préfixe' }
        ],
        selectedExt : [],
        header: "\u00a0\u00a0Nouvelle Configuration de touche",
        name: "",
        type: "BLF",
        value: "",
        label: "",
        extension: "none",
        line: "line1",
        key_id: 0,
        open: true
    };

    constructor(props) {
        super(props);
        this.refreshExtentionsList();
    };

    componentWillUnmount() {
        if (this.getextensions)
            this.getextensions.cancel();
    };

    componentDidMount() {
        if (this.props.value) {
            let newState = this.state;
            if (this.props.value.header)
                newState.header = this.props.value.header;
            if (this.props.value.name)
                newState.name = this.props.value.name;
            if (this.props.value.type)
                newState.type = this.props.value.type;
            if (this.props.value.value)
                newState.value = this.props.value.value;
            if (this.props.value.label)
                newState.label = this.props.value.label;
            if (this.props.value.extension)
                newState.extension = this.props.value.extension;
            if (this.props.value.line)
                newState.line = this.props.value.line;
            if (this.props.value.key_id)
                newState.key_id = this.props.value.key_id;
            else
                newState.key_id = 0;
            this.setState(newState);
        }
    };

    refreshExtentionsList = () => {
        this.getextensions = makeCancelable(this.props.client.get_extensions(this.props.server_id));
        this.getextensions.promise.then( response => {
            const selectedOptions = [{key:"none",value:"none",text:"Aucune"}];
            for (let i = 0; i !== this.props.extensions.data.length; i++) {
                let ext = {};
                ext.key = this.props.extensions.data[i].number;
                ext.value = ext.key;
                ext.text = this.props.extensions.data[i].name + " (#" + ext.key + ")";
                selectedOptions.push(ext);
            }
            this.setState({selectedExt: selectedOptions});
        });
    };

    checkButtonState() {
        if (this.state.name && this.state.name.length &&
            this.state.type && this.state.type.length &&
            (!this.hasValue() || (this.state.value && this.state.value.length)) &&
            (!this.hasLabel() || (this.state.label && this.state.label.length)) &&
            (!this.hasLine() || (this.state.line && this.state.line.length)))
            return false;
        return true;
    };

    handleChangeName = (e, item) => {
        this.setState({name:item.value});
    };

    handleChangeType = (e, item) => {
        this.setState({type:item.value});
    };

    handleChangeValue = (e, item) => {
        this.setState({value:item.value});
    };

    handleChangeLabel = (e, item) => {
        this.setState({label:item.value});
    };

    handleChangeExtension = (e, item) => {
        this.setState({extension:item.value});
    };


    hasLabel = () => {
        return (this.state.type !== 'Line');
    }
    hasExtension = () => {
        return (this.state.type === 'BLF');
    }
    hasValue = () => {
        return !(this.state.type === 'BLF' || this.state.type === 'Line');
    }
    hasLine = () => {
        return (this.state.type === 'BLF'
        || this.state.type === 'SpeedDial'
        || this.state.type === 'VoiceMail'
        || this.state.type === 'Line');
    }


    render() {
        return (
            <Modal style={{ userSelect: 'none' }} size="small" centered={false} open={true} onClose={() => this.props.onResult(null)}>
                <Modal.Header>
                    <Icon name='tty' circular color='blue' inverted/>
                    {this.state.header}
                </Modal.Header>
                <Modal.Content>
                    <Form style={{marginTop: 15}}>
                        <Form.Field>
                            <label>Nom</label>
                            <Input placeholder='Nom' value={this.state.name} onChange={this.handleChangeName}/>
                        </Form.Field>
                        <Form.Field>
                            <label>Type</label>
                            <Select value={this.state.type.length ? this.state.type : null} onChange={this.handleChangeType} placeholder={'Selectionner un type de Configuration'} options={this.state.selectedTypes} />
                        </Form.Field>
{this.hasExtension() &&
                        <Form.Field>
                            <label>Extension</label>
                            <Select value={this.state.extension} onChange={this.handleChangeExtension} placeholder={'Selectionner une Extension'} options={this.state.selectedExt} />
                        </Form.Field>
}
{this.hasValue() &&
                        <Form.Field>
                            <label>Valeur</label>
                            <Input placeholder='Valeur' value={this.state.value} onChange={this.handleChangeValue}/>
                        </Form.Field>
}
{this.hasLabel() &&
                        <Form.Field>
                            <label>Libéllé</label>
                            <Input placeholder='Libéllé' value={this.state.label} onChange={this.handleChangeLabel}/>
                        </Form.Field>
}
{this.hasLine() &&
                        <Form.Field>
                            <label>Ligne</label>
                            <Input disabled={true} placeholder='Ligne' value={this.state.line} onChange={()=>{}}/>
                        </Form.Field>
}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button size={UI_BUTTON_SIZE} onClick={() => this.props.onResult(null)}><Icon name='cancel'/>Annuler</Button>
                    <Button size={UI_BUTTON_SIZE} disabled={this.checkButtonState()} onClick={() => this.props.onResult(this.state)} color='blue'>Enregistrer&nbsp;&nbsp;<Icon name='check' /></Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    extensions : state.extensions
});

const mapDispatchToProps = dispatch => ({
    client: bindActionCreators(ClientActions, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalCreateConfigurationTouch);
