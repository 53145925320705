// import axios from 'axios';
import {
    axiosApi as axios,
    apiUrl
} from '../constants/index'


// /api/1.0/bicom/conf
export const send_conf = (server_id, phone_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id, phone_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/api/1.0/bicom/conf`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};

// /api/1.0/bicom/confall
export const send_all_conf = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/api/1.0/bicom/confall`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};

// /api/1.0/bicom/checkconf
export const check_conf = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
        axios.get(`${apiUrl}/api/1.0/bicom/checkconf`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};