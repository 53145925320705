import {
    LOGOUT_USER,
} from '../constants/index'

export const logout = () => ({ type: LOGOUT_USER });

export const handle_error = (dispatch, reject, error) => {
    console.log(1, error.response);
    console.log(2, error.message);
    if (!error.response)
        reject({ header: "Une erreur s'est produite.", message: error.message })
    else if (error.response.status === 401)
        dispatch(logout());
    else if (error.response.status === 403)
        reject({ header: "L'accès est refusé.", message: error.response.statusText })
    else
        reject({ header: "Une erreur s'est produite.", message: error.response.statusText })
}
