import { combineReducers } from 'redux';

import admin from './admin';
import partner from './partner';
import user from './user';
import phone_list from './phone_list';
import alarms from './alarms';
import extensions from './extensions';
import tenant from './tenant';

const reducers = combineReducers({
    admin,
    partner,
    phone_list,
    alarms,
    tenant,
    extensions,
    user,
});

export default reducers;