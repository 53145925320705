import React, { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { SemanticToastContainer, toast } from 'react-semantic-toasts';

import "./css/CreateTenant.css";
import "./css/CreateExtensions.css"

import Question from "../../Components/wizard/Question";
import Tip from "../../Components/wizard/Tip";
import Button from "../../Components/wizard/Button";
import { Icon } from "semantic-ui-react";
import TransferList from "../../Components/wizard/TransferList"
import ExtensionsSummary from "../../Components/wizard/ExtensionsSummary";
import validator from 'validator';
import { makeCancelable } from "../../utils";

import {
  get_available_uad,
  get_tenant_configuration,
  enable_uad,
  create_extensions
} from "../../actions/wizard";


class CreateExtensions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      loading: false,
      loadingMessage: 'Chargement en cours, veuillez patienter.',
      tenantConfiguration: {},
      available: [],
      selected: [],
      extensions: [{name: '', email: '', uad: '-1', extension: '', pin: '', mac: ''}],
      errors: [{name: true, email: true, uad: true, extension: true, pin: true, mac: true}],
      valid: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClickBack = this.handleClickBack.bind(this);
    this.onSelectedOption = this.onSelectedOption.bind(this);
    this.addExtension = this.addExtension.bind(this);
    this.deleteExtension = this.deleteExtension.bind(this);
    this.editExtension = this.editExtension.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.get_uad = makeCancelable(
      get_available_uad(this.props.server_id)
    );

    this.setState({
      loading: true,
      loadingMessage: 'Chargement des UADs, veuillez patienter.'
    });

    this.get_uad.promise
      .then((response) => {
        let uads = {};

        response.forEach(uad => {
          Object.assign(uads, {[uad.value]: uad.label})
        });

        this.setState({
          loading: false,
          uads,
          available: response
        });
      })
      .catch((error) => {
        console.error(error);
      });

    this.get_tenant_conf = makeCancelable(
      get_tenant_configuration(this.props.server_id)
    );

    this.get_tenant_conf.promise
      .then(response => {
        this.setState({
          tenantConfiguration: response
        })
      })
      .catch(error => {
        console.error(error);
      })
  }

  // Navigation vers l'étape suivante
  handleClick(e) {
    this.setState({
      step: this.state.step + 1,
    });
  }

  // Navigation vers l'étape précédente
  handleClickBack(e) {
    this.setState({
      step: this.state.step - 1,
    });
  }
  
  // Sélection d'un UAD dans la transfer list
  onSelectedOption(selected) {
    this.setState({ selected });
  }

  // Validation de la sélection dans la transfer list
  isValidSelection = () => {
    if (this.state.selected <= 0) {
      return false;
    }
    return true;
  };

  // Edition d'un champ d'extension
  editExtension(index, field) {
    const extensionToEdit = this.state.extensions[index];

    extensionToEdit[field.name] = field.value;

    this.setState((previousState) => {
      const extensions = previousState.extensions;
      extensions[index] = extensionToEdit;

      return {
        extensions,
      };
    }, () => this.validation());
  }

  // Ajout d'une extension
  addExtension() {
    if (this.state.extensions.length < Number.parseInt(this.state.tenantConfiguration.packageConfiguration.ext)) {
      this.setState((previousState) => {
        let errors = previousState.errors;
        let extensions = previousState.extensions;

        extensions.push({name: '', email: '', uad: '-1', extension: '', pin: '', mac: ''});
        errors.push({name: true, email: true, uad: true, extension: true, pin: true, mac: true});

        return {
          extensions,
          errors
        };
      }, () => this.validation());
    }
  }

  // Suppression d'une extension
  deleteExtension(index) {
    if (this.state.extensions.length > 1) {
      this.setState((previousState) => {
        const extensions = previousState.extensions;
        const errors = previousState.errors;

        extensions.splice(index, 1);
        errors.splice(index, 1);
  
        return {
          extensions,
          errors
        };
      }, () => this.validation());
    }
  }

  // Validation des extensions
  validation = () => {
    let errors = this.state.errors;
    let valid = true;

    this.state.extensions.forEach((extension, key) => {
      //Vérification nom
      if (validator.isEmpty(extension.name) === true) {
        errors[key].name = true;
        valid = false;
      } else {
        errors[key].name = false;
      }

      //Vérification email
      if (validator.isEmail(extension.email) === false) {
        errors[key].email = true;
        valid = false;
      } else {
        errors[key].email = false;
      }
      
      const isSelectedUad = this.state.selected.findIndex(uad => uad === Number.parseInt(extension.uad));

      //Vérification UAD
      if (isSelectedUad === -1 || isNaN(extension.uad)) {
        errors[key].uad = true;
        valid = false;
      } else {
        errors[key].uad = false;
      }

      // Vérification numéro d'extension
      if (validator.isInt(extension.extension) === false) {
        errors[key].extension = true;
        valid = false;
      } else {
        errors[key].extension = false;
      }

      // Vérification code PIN
      if (/^\d{4}$/.test(extension.pin) === false || extension.pin === '0000' || extension.pin === '1234') {
        errors[key].pin = true;
        valid = false;
      } else {
        errors[key].pin = false;
      }

      // Vérification adresse MAC
      if (validator.isMACAddress(extension.mac, { no_separators: true }) === false) {
        errors[key].mac = true;
        valid = false;
      } else {
        errors[key].mac = false;
      }
    })

    this.setState({
      errors,
      valid
    });
  };

  // Soumission des informations
  handleSubmit(e) {
    let enableUads = [];

    this.setState({
      loading: true,
      loadingMessage: 'Activation des UADs, veuillez patienter.'
    })

    // Activation des UADs
    this.state.selected.forEach(uad => enableUads.push(enable_uad(this.props.server_id, uad)));

    // Attente de toutes les réponses
    Promise.all(enableUads)
      .then(result => {
        this.setState({ loadingMessage: 'Création des extensions, veuillez patienter.' });

        let currentExtensionIndex = 0;

        // Création des extensions, mise en place d'un délai de 3 secondes entre chaque appel pour éviter l'erreur PBWware "You are not allowed to add more extensions."
        let creatingExtensions = setInterval(async () => {
          const currentExtension = this.state.extensions[currentExtensionIndex];

          this.setState({ loadingMessage: `Création de l'extension numéro ${currentExtension.extension}...` })

          try {
            const createExtensionResult = await create_extensions(this.props.server_id, currentExtension);

            if (currentExtensionIndex === this.state.extensions.length - 1) {
              this.setState({ loading: false });
              
              clearInterval(creatingExtensions);

              this.props.history.push(`/client/${createExtensionResult.client_id}/phones?server_id=${this.props.server_id}`);
            
              return;
            }

            currentExtensionIndex++;
          } catch (e) {
            console.error(e);
    
            this.setState({ loading: false });
    
            toast({
              type: 'close',
              icon: 'close',
              title: 'Erreur',
              description: `Une erreur est survenue lors de la création de l'extension numéro ${currentExtension.extension}, abandon.`,
              time: 5000
            });

            return clearInterval(creatingExtensions);
          }
        }, 3000);
      })
      .catch(e => {
        console.error(e);

        this.setState({ loading: false });

        toast({
          type: 'close',
          icon: 'close',
          title: 'Erreur',
          description: `Une erreur est survenue lors de l'activation des UADs, abandon.`,
          time: 5000
        });
      })
  }

  componentWillUnmount() {
    if (this.get_uad) this.get_uad.cancel();
    if (this.get_tenant_conf) this.get_tenant_conf.cancel();
  }

  render() {
    return (
      <div className="Wizard">
        {this.state.loading &&
          <Dimmer active inverted>
            <Loader>{this.state.loadingMessage}</Loader>
          </Dimmer>
        }
        <SemanticToastContainer position="top-right" />
        <button className="leaveButton" onClick={e => this.props.history.push("/")}><Icon name='close' /></button>
        <div className="step" style={{ display: this.state.step === 1 ? "block" : "none" }}>
          <Question>Quels téléphones seront utilisés ?</Question>
          <Tip>Ils seront assignés à vos extensions</Tip>
          {!this.state.loading &&
            <TransferList available={this.state.available} onChange={this.onSelectedOption} selected={this.state.selected}  />
          }
          <Button
            className="nextButtonTransferList"
            onClick={this.handleClick}
            buttonName="Suivant"
            disabled={!this.isValidSelection()}
            />
        </div>
        <div className="step" style={{ display: this.state.step === 2 ? "block" : "none" }}>
          <Question>Quelles sont les informations de ces extensions ?</Question>
          <Tip>Pas de panique, vous aurez toujours la possibilité de les modifier ultérieurement</Tip>
          
          <div className="tableContainer">
          <table className="extensionTable -striped" >
              <thead className="tableHeader" style={{ backgroundColor: '#205a8c', color: 'white' }} >
                <tr>
                  <td>Nom</td>
                  <td>Email</td>
                  <td>UAD</td>
                  <td>Numéro</td>
                  <td>PIN</td>
                  <td>Adresse MAC</td>
                  <td>Supprimer</td>
                </tr>
              </thead>
              <tbody>
                {this.state.extensions.map((extension, key) => (
                  <tr className="tableRow" key={key}>
                    <td><input className={this.state.errors[key].name ? "extensionInput error" : "extensionInput"} type="text" name="name" placeholder="Mon extension" value={extension.name} onChange={e => this.editExtension(key, e.target)} /></td>
                    <td><input className={this.state.errors[key].email ? "extensionInput error" : "extensionInput"} type="text" name="email" placeholder="extension@domaine.fr" value={extension.email} onChange={e => this.editExtension(key, e.target)} /></td>
                    <td>
                      <select className={this.state.errors[key].uad ? "selectedUads error" : "selectedUads"} type="text" name="uad" value={extension.uad} onChange={e => this.editExtension(key, e.target)}>
                        <option value="-1">Sélectionnez un UAD</option>
                        {this.state.selected.map((uad, index) => (
                          <option key={index} value={uad}>{this.state.uads[uad]}</option>
                        ))}
                      </select>
                    </td>
                    <td><input className={this.state.errors[key].extension ? "extensionInput error" : "extensionInput"} type="text" name="extension" placeholder="000" value={extension.extension} onChange={e => this.editExtension(key, e.target)} /></td>
                    <td><input className={this.state.errors[key].pin ? "extensionInput error" : "extensionInput"} type="text" name="pin" placeholder="0000" value={extension.pin} onChange={e => this.editExtension(key, e.target)} /></td>
                    <td><input className={this.state.errors[key].mac ? "extensionInput error" : "extensionInput"} type="text" name="mac" placeholder="112233445566" value={extension.mac} onChange={e => this.editExtension(key, e.target)} /></td>
                    <td>
                      <button className="deleteButton" onClick={e => this.deleteExtension(key)}><Icon name='trash alternate outline' /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan='6'>
                    <button onClick={this.addExtension} className="addButton"><Icon name='add circle' /></button>
                  </td>
                </tr>
              </tfoot>
          </table>
          </div>
          <div>
            <Button
              className="back"
              onClick={this.handleClickBack}
              disabled={false}
              buttonName={<Icon name="angle left" />}
              />
            <Button
              onClick={this.handleClick}
              buttonName="Suivant"
              disabled={!this.state.valid}
              />
          </div>
        </div>
        <div className="step" style={{ display: this.state.step === 3 ? "block" : "none" }}>
          <Question>Voici le récapitulatif des informations de vos nouvelles extensions</Question>
          <Tip>Vérifiez si tout vous convient</Tip>
          <div className="SummaryContainer">
            {this.state.uads != null &&
              this.state.extensions.map((extension, key) => (
                <ExtensionsSummary extension={extension} uadLabel={this.state.uads[extension.uad]} editStep={(e) => this.setState({ step: 2 })} key={key} />
              ))
            }
          </div>
          <div>
            <Button
              className="back"
              onClick={this.handleClickBack}
              disabled={false}
              buttonName={<Icon name="angle left" />}
              />
            <Button
              onClick={this.handleSubmit}
              disabled={false}
              buttonName="Valider"
              />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateExtensions;