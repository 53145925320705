// import axios from 'axios';
import {
    axiosApi as axios,
    apiUrl,
    KEY_GET_LIST,
    KEY_GET_LIST_SUCCESS,
    KEY_GET_LIST_FAILED,

} from '../constants/index'

export const key_getlist = () => ({ type: KEY_GET_LIST });
export const key_getlist_success = (data) => ({ type: KEY_GET_LIST_SUCCESS, payload: data });
export const key_getlist_failed = (error) => ({ type: KEY_GET_LIST_FAILED, payload: error });


// /api/1.0/customkey
export const post_keysconfiguration = (client_id, name, label, type, value, line, extension, key_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {key_id, client_id, name, label, type, value, line, extension}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.post(`${apiUrl}/api/1.0/customkey`, null, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};

// /api/1.0/keysconfiguration
export const get_keysconfiguration = (server_id) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = { params: {server_id}, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/keysconfiguration`, config)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                // todo: gestion du 401
                reject({ header: "Erreur!", message: error.message });
            })
        });
        return p;
    };
};



// /api/1.0/keys
export const get_phone_keys = (phone_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { phone_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/keys`, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    // todo: gestion du 401
                    reject({ header: "Erreur!", message: error.message });
                })
        });
        return p;
    };
};


// /api/1.0/key
export const get_phone_key = (phone_id, position, type) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { phone_id, position, type }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.get(`${apiUrl}/api/1.0/key`, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    // todo: gestion du 401
                    reject({ header: "Erreur!", message: error.message });
                })
        });
        return p;
    };
};


// /api/1.0/key
export const link_phone_key = (phone_id, position, type, key_id) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { phone_id, position, type, key_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.put(`${apiUrl}/api/1.0/key`, null, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    // todo: gestion du 401
                    reject({ header: "Erreur!", message: error.message });
                })
        });
        return p;
    };
};


// /api/1.0/key
export const unlink_phone_key = (phone_id, position, type) => {
    return (dispatch, getState) => {

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { phone_id, position, type }, headers: { 'Authorization': "Bearer " + sessionId } };

        const p = new Promise((resolve, reject) => {
            axios.delete(`${apiUrl}/api/1.0/key`, config)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    // todo: gestion du 401
                    reject({ header: "Erreur!", message: error.message });
                })
        });
        return p;
    };
};
