import React, { Component, Fragment } from 'react';
import { Table, Icon, Header, Menu } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import AppMenuContainer from "../containers/AppMenuContainer";

class AppHeader extends Component {
    state = {
        referer:null
    };
    render() {
        const {icon, header} = this.props;
        return (
            <Fragment>
                {this.state.referer && <Redirect to={this.state.referer}/>}
                <AppMenuContainer></AppMenuContainer>
                <div style={{paddingTop: '65px'}} className="detailview-header-block" >
                    <Table basic='very' collapsing>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Icon size='big' bordered inverted color='blue' name={icon} />
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle'>
                                    <Header as='h4'>
                                        <Menu text size="huge">
                                            {this.props.user && this.props.user.user.role !== 'user' && <Menu.Item style={{textDecoration:'underline',fontWeight:"bold"}} onClick={()=>this.setState({referer:'/partner/'+this.props.owner_id})}>Accueil</Menu.Item>}
                                            {this.props.user && this.props.user.user.role !== 'user' && <Menu.Item>/</Menu.Item>}
                                            <Menu.Item style={{fontWeight:"bold"}}>{header}</Menu.Item>
                                        </Menu>
                                    </Header>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </Fragment>
        );
    }
}

export default AppHeader;
