// import axios from 'axios';
import { axiosApi as axios } from './constants/index'
import { logout } from './actions/session'

export default {
  setupInterceptors: (store) => {
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        //catches if the session ended!
        if (error.response.status === 401) {
            store.dispatch(logout());
            //localStorage.clear();
        }
        // todo: reformat error ??
        return Promise.reject(error);
    });
  }
};