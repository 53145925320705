import axios from 'axios';
export const app = window.app;



// common
export const MODULE_NAME = 'LounaPhone';
export const MODE_ACTION_ADMIN = "ActionAdmin";
export const MODE_ACTION_PARTNER = "ActionPartner";
export const MODE_ACTION_CLIENT = "ActionClient";
export const MODE_ACTION_USER = "ActionUser";


export const UPDATE_ME = 'UPDATE_ME';


//Get current user(me) from token in localStorage
export const ME_FROM_TOKEN = 'ME_FROM_TOKEN';
export const ME_FROM_TOKEN_SUCCESS = 'ME_FROM_TOKEN_SUCCESS';
export const ME_FROM_TOKEN_FAILURE = 'ME_FROM_TOKEN_FAILURE';
export const RESET_TOKEN = 'RESET_TOKEN';

//Sign In User
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_RESET= 'SIGNIN_USER_RESET';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER';

// admin
export const ADMIN_GET_PARTNER = 'ADMIN_GET_PARTNER';
export const ADMIN_GET_PARTNER_SUCCESS = 'ADMIN_GET_PARTNER_SUCCESS';
export const ADMIN_GET_PARTNER_FAILED = 'ADMIN_GET_PARTNER_FAILED';

export const PARTNER_GET_CLIENTLIST = 'PARTNER_GET_CLIENTLIST';
export const PARTNER_GET_CLIENTLIST_SUCCESS = 'PARTNER_GET_CLIENTLIST_SUCCESS';
export const PARTNER_GET_CLIENTLIST_FAILED = 'PARTNER_GET_CLIENTLIST_FAILED';

export const PARTNER_GET_CLIENTSTATS_SUCCESS = 'PARTNER_GET_CLIENTSTATS_SUCCESS';
export const PARTNER_GET_CLIENTSTATS_FAILED = 'PARTNER_GET_CLIENTSTATS_FAILED';


// client

// set server
export const SERVER_SET_CONF = 'SERVER_SET_CONF';
export const SERVER_SET_CONF_SUCCESS = 'SERVER_SET_CONF_SUCCESS';
export const SERVER_SET_CONF_FAILED = 'SERVER_SET_CONF_FAILED';

// get tenant
export const TENANT_GET_CONF = 'TENANT_GET_CONF';
export const TENANT_GET_CONF_SUCCESS = 'TENANT_GET_CONF_SUCCESS';
export const TENANT_GET_CONF_FAILED = 'TENANT_GET_CONF_FAILED';


// phonelist
export const CLIENT_GET_PHONE_LIST = 'CLIENT_GET_PHONE_LIST';
export const CLIENT_GET_PHONE_LIST_SUCCESS = 'CLIENT_GET_PHONE_LIST_SUCCESS';
export const CLIENT_GET_PHONE_LIST_FAILED = 'CLIENT_GET_PHONE_LIST_FAILED';
export const CLIENT_SELECT_PHONE = 'CLIENT_SELECT_PHONE';

// alarmlist
export const CLIENT_GET_ALARM_LIST = 'CLIENT_GET_ALARM_LIST';
export const CLIENT_GET_ALARM_LIST_SUCCESS = 'CLIENT_GET_ALARM_LIST_SUCCESS';
export const CLIENT_GET_ALARM_LIST_FAILED = 'CLIENT_GET_ALARM_LIST_FAILED';

// extensions
export const CLIENT_GET_EXTENSIONS = 'CLIENT_GET_EXTENSIONS';
export const CLIENT_GET_EXTENSIONS_SUCCESS = 'CLIENT_GET_EXTENSIONS_SUCCESS';
export const CLIENT_GET_EXTENSIONS_FAILED = 'CLIENT_GET_EXTENSIONS_FAILED';



// interface
export const UI_BUTTON_SIZE = 'tiny';
// api
// export const apiUrl = 'https://maconfig.tel:2443';
export const apiUrl = '';

export const axiosApi = process.env.NODE_ENV === 'development' ? axios.create() : axios.create({
    baseURL: window.location.origin + ':2443'
})

//export const apiUrl = 'http://127.0.0.1';

export const ALARM_GET_WAKEUP = 'ALARM_GET_WAKEUP';
export const ALARM_GET_WAKEUP_SUCCESS = 'ALARM_GET_WAKEUP_SUCCESS';
export const ALARM_GET_WAKEUP_FAILED = 'ALARM_GET_WAKEUP_FAILED';

export const KEY_GET_LIST = 'KEY_GET_LIST';
export const KEY_GET_LIST_SUCCESS = 'KEY_GET_LIST_SUCCESS';
export const KEY_GET_LIST_FAILED = 'KEY_GET_LIST_FAILED';

export const DASHBOARD_GETSTATS = 'DASHBOARD_GETSTATS';
export const DASHBOARD_GETSTATS_SUCCESS = 'DASHBOARD_GETSTATS_SUCCESS';
export const DASHBOARD_GETSTATS_FAILED = 'DASHBOARD_GETSTATS_FAILED';
