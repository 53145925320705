// import axios from 'axios';
import {
    axiosApi as axios,
    apiUrl,
    PARTNER_GET_CLIENTLIST,
    PARTNER_GET_CLIENTLIST_SUCCESS,
    PARTNER_GET_CLIENTLIST_FAILED,
    PARTNER_GET_CLIENTSTATS_SUCCESS,
    PARTNER_GET_CLIENTSTATS_FAILED
} from '../constants/index';

export const partner_getclientlist = () => ({ type: PARTNER_GET_CLIENTLIST });
export const partner_getclientlist_success = (data) => ({ type: PARTNER_GET_CLIENTLIST_SUCCESS, payload: data });
export const partner_getclientlist_failed = (error) => ({ type: PARTNER_GET_CLIENTLIST_FAILED, payload: error });

export const partner_getclientstats_success = (data) => ({ type: PARTNER_GET_CLIENTSTATS_SUCCESS, payload: data });
export const partner_getclientstats_failed = (error) => ({ type: PARTNER_GET_CLIENTSTATS_FAILED, payload: error });


// /api/1.0/getmyclients
// /api/1.0/getclients
export const get_client_list = (user_id) => {
    return (dispatch, getState) => {
        const { user } = getState().user;

        let sessionId = localStorage.getItem('x-token');
        let config = { params: { user_id }, headers: { 'Authorization': "Bearer " + sessionId } };

        const path = user.role === 'admin' ? `${apiUrl}/api/1.0/clients` : `${apiUrl}/api/1.0/myclients`
        dispatch(partner_getclientlist());
        return axios.get(path, config)
            .then(response => {
                dispatch(partner_getclientlist_success(response.data));
            })
            .catch(error => {
                if (!error.response)
                    dispatch(partner_getclientlist_failed({ header: "Erreur!", message: error.message }));
                /*
                else if (error.response.status === 401)
                    dispatch(signin_failed({ header: "L'authentification a échoué!", message: error.response.data.log }));
                else
                    dispatch(signin_failed({ header: "L'authentification a échoué!", message: error.response.statusText }));
                */
            })
    };
};

// /api/1.0/getStats
export const get_clients_servers_stats = (client_list) => {
    return (dispatch, getState) => {
        let sessionId = localStorage.getItem('x-token');
        let config = {
            headers: { 'Authorization': "Bearer " + sessionId }
        };

        return axios.post(`${apiUrl}/api/1.0/getStats`, client_list, config)
            .then((response) => {
                dispatch(partner_getclientstats_success(response.data));
            })
            .catch((error) => {
                dispatch(partner_getclientstats_failed({ header: "Erreur!", message: error.error }));
            });
    };
}

