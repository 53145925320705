import { connect } from 'react-redux';
import FormTenant from '../Components/FormTenant';
import { get_server_tenant } from '../actions/server'



function mapStateToProps(state, ownProps) {
    return {
        tenant: state.tenant
    };
}

export default connect(mapStateToProps, { get_server_tenant })(FormTenant);