import React, { Component, Fragment } from 'react'
import { Modal, Button, Icon, Form, Segment, Divider, Item, Loader, Dimmer } from 'semantic-ui-react';
import { UI_BUTTON_SIZE } from '../../constants/index';

import ErrorMessage from '../../Components/ErrorMessage';

import { connect } from 'react-redux';
import { post_server_configuration } from '../../actions/server';
import { get_server_configuration } from '../../actions/client';
import { makeCancelable } from '../../utils'


class EditServerModal extends Component {
    constructor(props) {
        super(props);
        this.state= { loading: true, error: null }
        this.base = this.state;
    }

    handleChange = (section, membre) => (event, {value}) => {
        const state = {...this.state};
        state[membre] = value;
        this.setState(state);
    }

    handleValidate = () => {
        if (!this.state.server_id) return false;
        const { name, server_url, server_login, server_mdp } = this.state;
        const { uad_login, uad_password } = this.state;
        const bicom = { name, server_url, server_login, server_mdp };
        const uad = { uad_login, uad_password };

        this.setState({ error: null, loading: true });
        this.p_post = makeCancelable(this.props.post_server_configuration(this.props.item.server_id, 0, bicom, {}, uad));
        this.p_post.promise.then( payload => {
            this.props.onClose(true);
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, loading: false });
        });
    }

    isValidForm = () => {
        if (this.state.loading || !this.state.server_id)
            return false;
       if (this.state.server_login.length && this.state.server_mdp.length)
            return true;
        return false;
    }

    handleClose = () => {
        this.setState(this.base)
        this.props.onClose(false);
    }

    componentWillUnmount() {
        if (this.p_get)  this.p_get.cancel();
        if (this.p_post) this.p_post.cancel();
    }

    componentDidMount() {
        // get info from server
        this.p_get = makeCancelable(this.props.get_server_configuration(this.props.item.server_id));
        this.p_get.promise.then( payload => {
            this.setState({ ...payload, error: null, loading: false });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, loading: false });
        });
        this.setState({ error:null, loading: true })
    }


  render() {
      const {error, loading } = this.state;
      return (
        <Fragment>
        <Modal
            open={true}
            onOpen={this.handleOpened}
            onClose={this.handleClose}
            centered={false}
            style={{ userSelect: 'none' }}
            size='small'>
            <Modal.Header>
                <Icon circular inverted color='blue' name='server' />
                {'\u00a0\u00a0'}Configuration de l'environnement
                </Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <Segment>
                        <Item.Group>
                            <Item>
                                <Icon name='server' color="blue" size='huge' />
                                <Item.Content>
                                    <Item.Meta as='h3'>{this.props.header}</Item.Meta>
                                    {/* <Item.Meta>{this.state.server_name}</Item.Meta> */}
                                    <Item.Meta>{this.state.server_url}</Item.Meta>
                                </Item.Content>
                            </Item>
                        </Item.Group>

                        <Form style={{ minHeight: 202 }} onSubmit={this.nextStep}>
                            {loading && <Dimmer active inverted><Loader /></Dimmer>}
                            {error && (<ErrorMessage error={error}></ErrorMessage>)}
                            {!loading && !error &&  (<Fragment>
                            <Form.Group widths='equal'>
                                <Form.Input autoFocus fluid label='Login Bicom' placeholder='Login Bicom' value={this.state.server_login} onChange={this.handleChange('bicom', 'server_login')} />
                                <Form.Input fluid type='password' label='Mot de passe Bicom' placeholder='Mot de passe Bicom' value={this.state.server_mdp} onChange={this.handleChange('bicom', 'server_mdp')} />
                            </Form.Group>
                            <Divider section></Divider>
                            <Form.Group widths='equal'>
                                <Form.Input fluid label='Login UAD' placeholder='Login UAD' value={this.state.uad_login} onChange={this.handleChange('uad', 'uad_login')} />
                                <Form.Input fluid type='password' label='Mot de passe UAD' placeholder='Mot de passe UAD' value={this.state.uad_password} onChange={this.handleChange('uad', 'uad_password')} />
                            </Form.Group>

                        </Fragment>)}
                        </Form>

                    </Segment>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button size={UI_BUTTON_SIZE} onClick={this.handleClose}><Icon name='cancel'/>Annuler</Button>
                <Button disabled={!this.isValidForm()} size={UI_BUTTON_SIZE} primary onClick={this.handleValidate}>Enregistrer&nbsp;&nbsp;<Icon name='check' /></Button>
            </Modal.Actions>
        </Modal>
        </Fragment>
    )
  }
}


const mapStateToProps = state => ({
});


export default connect(
    mapStateToProps,
    { get_server_configuration, post_server_configuration},
)(EditServerModal);

