import { connect } from 'react-redux';
import AppMenu from '../Components/AppMenu';
import { logout } from '../actions/session'


function mapStateToProps(state, ownProps) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps, { logout })(AppMenu);