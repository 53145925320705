import React, { Component } from 'react';
import './css/Question.css'

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: props.children,
    };
  }
  
  render() {
    return (
        <h1 className='Question'>{this.state.question}</h1>
    );
  }
}

export default Question;