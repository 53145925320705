import React, { Component } from 'react';

import { Form, Image } from 'semantic-ui-react';
import {InputLine} from '../../../Components/InputLine'


class BicomForm extends Component {
    render() {
        const { data } = this.props;
        return (
            <Form style={{ marginTop: '30px' }}>
                <InputLine label='Nom du serveur'            value={data.server_name}></InputLine>
                <InputLine label='Adresse du serveur Bicom'  value={data.server_url}></InputLine>
                <InputLine label='Login Bicom'               value={data.server_login}></InputLine>
                <InputLine label='Mot de passe Bicom'        value={data.server_mdp}></InputLine>
                <Image src='/images/Bicom-logo.png' centered />
            </Form>
        )
    }
}


export default BicomForm;
