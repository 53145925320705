import React, { Component } from 'react';
import './css/TextBox.css'


class TextBox extends Component {

  render() {
    const {name, value, placeholder, onChange} = this.props;

    return (
      <input className="TextBox" type="text" name={name} placeholder={placeholder} value={value} onChange={onChange}/>
    );
  }

}
  
export default TextBox;