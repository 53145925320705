import React, { Component } from 'react';

class SelectOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      label: props.label
    };
  }
  
  render() {
    return (
        <option value={this.state.value}>{this.state.label}</option>
    );
  }
}

export default SelectOption;