import React, { Component } from 'react';

import { Segment, Grid, Button, Icon, Divider, Container, Loader, Dimmer } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { get_server_configuration } from '../../../actions/client';

import BicomForm from './Bicom'
import UadForm from './uad'

import {
    UI_BUTTON_SIZE
} from '../../../constants/index';

import { makeCancelable } from '../../../utils'
import EditServerModal from '../../partner/EditServerModal';



class Configuration extends Component {

    constructor(props) {
        super(props);

        this.state= { open: false, loading: true, error: null, payload:{} }
        this.base = this.state;


    }

    componentDidMount() {
        this.p = makeCancelable(this.props.get_server_configuration(this.props.server_id));
        this.p.promise.then( payload => {
            this.setState({ payload, error: null, loading: false });
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ error, loading: false });
        });
        this.setState({ error: null, loading: true });
    }

    componentWillUnmount() {
        if (this.p)
            this.p.cancel();
    }

    handleEditServer = () => {
        this.setState({open: true})
    }
    handleClose = () => {
        this.setState({open: false})
        this.componentDidMount();
    }
//            <Segment basic style={{minHeight:'50vh'}}>

    render() {
        const { payload, loading, open } = this.state;
        return (
            <div style={{marginTop:25,marginLeft:15,marginRight:15}}>
                {open && <EditServerModal header={payload.server_name} item={payload} onClose={this.handleClose} ></EditServerModal>}
                {loading ? <Dimmer inverted active><Loader /></Dimmer> :
                <React.Fragment>
                <Container fluid textAlign='left'>
                    <Button size={UI_BUTTON_SIZE} color="blue" onClick={this.handleEditServer}> <Icon name='edit' />Editer</Button>
                </Container>
                <Divider/>
                <Segment>
                    <Grid columns='2' divided stackable>
                        <Grid.Row>
                            <Grid.Column >
                                <BicomForm data={payload}></BicomForm>
                            </Grid.Column>
                            <Grid.Column>
                                <UadForm data={payload} sync={() => window.location='https://maconfig.tel:1443/customlogin/' + payload.single_use + '?client_id=' + this.props.client_id + '&redirect=' + encodeURIComponent(window.location)} deSync={() => window.location = 'https://maconfig.tel:1443/disconnect?single_use=' + payload.single_use + '&client_id=' + this.props.client_id + '&redirect=' + encodeURIComponent(window.location)}></UadForm>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                </React.Fragment>
                }
            </div>
        );
    }
}


const mapStateToProps = state => ({
    payload: state.client
});

export default connect(
    mapStateToProps,
    { get_server_configuration },
)(Configuration);

