import React, { Component } from 'react';

import { Segment, Button, Input,
    Label, Icon, Divider, Grid,
    Loader, Dimmer } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ClientActions from '../../../actions/client';
import * as BicomActions from '../../../actions/bicom';
import Locations from '../../Locations';

import { makeCancelable } from '../../../utils'

import LounaTable from '../../../Components/LounaTable'
import ListRemoveConfirm from '../../../Components/ListRemoveConfirm';

import {
    UI_BUTTON_SIZE
} from '../../../constants/index';
import { toast } from 'react-semantic-toasts';


class PhoneList extends Component {
    constructor(props) {
        super(props);
        this.state = { filter: '', disabled_refresh : false, disabled_deploy : false }
        this.get_phone_list = makeCancelable(this.props.client.get_phone_list(this.props.server_id));
    }

    componentWillUnmount() {
        if (this.sync_phone_list)
            this.sync_phone_list.cancel();
        if (this.check_sync_phone_list)
            this.check_sync_phone_list.cancel();
        if (this.get_phone_list)
            this.get_phone_list.cancel();
        if (this.timer)
            clearInterval(this.timer);
        if (this.send_all_conf)
            this.send_all_conf.cancel();
        if (this.check_conf)
            this.check_conf.cancel();

        // if (this.delete_phone)
        //     this.delete_phone.cancel();
        // if (this.sync_delete)
        //     this.sync_delete.cancel();
    };

    renderTags = (phone) => {
        return phone.tags.map((tag, idx) => {
            return <Label key={idx} size='small' tag>{tag}</Label>
        })
    }
    handleStatus = (d) =>{
        if (d.constructor === 'none')
            return (<Label circular  style={{maxWidth: '90px'}} size='small'>non supporté</Label>)
        let c = (d.status === 'enabled') ? 'green': 'red';
        return (<Label circular color={c} style={{maxWidth: '90px'}} size='small'>supporté</Label>)
    }

    handleMenuRow = (d) => {
        let phone = d;

        return (
            <Grid stackable columns={1}>
                <Grid.Column textAlign="right">
                    <ListRemoveConfirm header='Confirmation de suppression' icon='trash alternate' label={phone.phone_name} onValidate={this.handleRemove(phone)}></ListRemoveConfirm>
                </Grid.Column>
            </Grid>
        )
    }

    handleRemove = item => event => {
        event.stopPropagation();

        this.delete_phone = makeCancelable(this.props.client.delete_phone(item.phone_id, item.extension_number, this.props.server_id));
        
        this.delete_phone.promise
        .then((response) => {
            this.sync_delete = makeCancelable(this.props.client.sync_delete_phone(item.extension_number, this.props.server_id));

            this.sync_delete.promise
            .then((res) => {
                toast({ type: 'success', icon: 'check circle', title: 'Succès', description: "L'extension " + item.extension_number + " a bien été supprimé.", time: 5000 });    
            })
            .catch((error) => {
                toast({type: 'error', icon: 'warning circle', title: 'Erreur', description: "Une erreur est survenue lors de la suppression de l'extension " + item.extension_number + " dans Bicom.", time: 5000 }); 
            })
            this.props.client.get_phone_list(this.props.server_id); 
        })
        .catch((error) => {
            toast({type: 'error', icon: 'warning circle', title: 'Erreur', description: "Une erreur est survenue lors de la suppression de l'extension " + item.extension_number + ".", time: 5000 });
        });      
        
        
       
    }

    handleSearch = (e) => {
        this.setState({ filter : e.target.value });
    }

    handleDisplay = (e, item)=>{
        e.stopPropagation();
        const {client_id, server_id} = this.props;
        this.props.history.push(Locations.ClientPhoneView.toUrl({ client_id, server_id, phone_id: item.phone_id }));
    }

    handleRefresh = () => {
        this.setState({disabled_deploy:true,disabled_refresh:true});
        this.sync_phone_list = makeCancelable(this.props.client.sync_phone_list(this.props.server_id));
        this.sync_phone_list.promise.then( response => {
            if (response && response.status === 'running') {
                this.timer = setInterval(()=> {
                    this.check_sync_phone_list = makeCancelable(this.props.client.check_sync_phone_list(this.props.server_id));
                    this.check_sync_phone_list.promise.then( response => {
                        if (response && response.status !== 'running') {
                            clearInterval(this.timer);
                            this.get_phone_list = makeCancelable(this.props.client.get_phone_list(this.props.server_id));
                            this.get_phone_list.promise.then( response => {
                                this.setState({disabled_deploy:false, disabled_refresh:false});
                            });
                        }
                    });
                }, 500);
            } else {
                this.get_phone_list = makeCancelable(this.props.client.get_phone_list(this.props.server_id));
                this.get_phone_list.promise.then( response => {
                    this.setState({disabled_deploy:false, disabled_refresh:false});
                });
            }
        });
    };

    handleDeploy = () => {
        this.setState({disabled_deploy:true,disabled_refresh:true});
        this.send_all_conf = makeCancelable(this.props.bicom.send_all_conf(this.props.server_id));
        this.send_all_conf.promise.then( response => {
            if (response && response.status === 'running') {
                this.timer = setInterval(()=> {
                    this.check_conf = makeCancelable(this.props.bicom.check_conf(this.props.server_id));
                    this.check_conf.promise.then( response => {
                        if (response && response.status !== 'running') {
                            clearInterval(this.timer);
                            this.setState({disabled_deploy:false,disabled_refresh:false});
                        }
                    });
                }, 500);
            } else {
                this.setState({disabled_deploy:false,disabled_refresh:false});
            }
        });
    };

    render() {
        const { data, loading } = this.props.phones;
        const filter = this.state.filter.toLowerCase();

        let filtred = [];
        if (filter.startsWith('#')){
            const f = filter.substr(1);
            filtred = data.filter((item) => {
                return item.tags.find(tag => {
                    return tag.toLowerCase().search(f) !== -1
                });
            });
        }
        else {
            filtred = data.filter((item) => {
                return item.phone_name.toLowerCase().search(filter) !== -1 ||
                item.uad_name.toLowerCase().search(filter) !== -1 ||
                item.extension_number.toLowerCase().search(filter) !== -1;
            });
        }

        return (
            <Segment basic style={{ minHeight: '50vh' }}>
                {loading ? <Dimmer inverted active><Loader /></Dimmer> : null}

                    <div>
                        <Grid stackable columns={2}>
                            <Grid.Column textAlign="left">
                                <Button size={UI_BUTTON_SIZE} color="blue" disabled={this.state.disabled_refresh} loading={this.state.disabled_refresh} onClick={this.handleRefresh}><Icon name='sync' />Actualiser</Button>
                                <Button disabled={this.state.disabled_deploy} loading={this.state.disabled_deploy} style={{marginLeft:10}} size={UI_BUTTON_SIZE} color="blue" onClick={()=> this.handleDeploy()}> <Icon name='play' />Tout Deployer</Button>
                                <Button size={UI_BUTTON_SIZE} primary onClick={() => this.props.history.push('/wizard/extensions/' + this.props.server_id)} ><Icon name='user plus' />Créer des extensions</Button>
                            </Grid.Column>
                            <Grid.Column textAlign="right">
                                <Input icon='search' placeholder='Recherche, #département...' size='small' style={{ marginRight: "10px" }} onChange={ this.handleSearch }></Input>
                            </Grid.Column>
                        </Grid>
                        <Divider />
                        
                        <LounaTable
                            data={filtred}
                            columns={[
                                {
                                    Header: "Extension",
                                    accessor: "extension_number",
                                    maxWidth: 100,
                                }, {
                                    Header: "Nom",
                                    accessor: "phone_name",
                                }, {
                                    Header: "Département(s)",
                                    id: 'tags',
                                    accessor: this.renderTags,
                                    sortable: false,
                                    maxWidth: 150,
                                }, {
                                    Header: "UAD",
                                    accessor: "uad_name",
                                    maxWidth: 150,
                                }, {
                                    Header: "Adresse MAC",
                                    accessor: "mac",
                                    maxWidth: 150,
                                }, {
                                    Header: "Etat",
                                    id: 'status',
                                    maxWidth: 150,
                                    accessor: this.handleStatus,
                                    sortMethod: (a, b, c)=>{
                                        const aa = a.props.children;
                                        const bb = b.props.children;
                                        if (aa < bb) return 1;
                                        if (aa > bb) return -1;
                                        return 0;
                                    }
                                }, {
                                    Header: "",
                                    id: 'action',
                                    maxWidth: 50,
                                    accessor: this.handleMenuRow
                                }
                            ]}
                            getTrProps={(state, rowInfo) => {
                                let style = {};
                                if (rowInfo) {
                                    if (rowInfo.original.constructor === 'none') {
                                        return {
                                            style, className:"disabled"
                                        };
                                    }
                                    style = { cursor: 'pointer' }
                                    return {
                                        style,
                                        onClick: (e) => this.handleDisplay(e, rowInfo.original)
                                    };
                                }
                                return {};
                            }}

                        />

                    </div>

            </Segment>
        );
    }
}


const mapStateToProps = state => ({
    phones : state.phone_list
});

const mapDispatchToProps = dispatch => ({
    client: bindActionCreators(ClientActions, dispatch),
    bicom: bindActionCreators(BicomActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PhoneList);

