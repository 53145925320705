import React, { Component } from 'react';

import { Form, Message, Icon, Container, Button } from 'semantic-ui-react';
import { InputLine } from '../../../Components/InputLine'


class UadForm extends Component {
    render() {
        const { data } = this.props;
        return (
            <Form style={{ marginTop: '30px' }}>
                <InputLine label='Login UAD' value={data.uad_login}></InputLine>
                <InputLine label='Mot de passe UAD' value={data.uad_password}></InputLine>

                <Container>
                    <Message icon warning visible size="mini" >
                        <Icon name='address book outline' />
                        <Message.Content>
                            <Message.Header>Synchroniser les contacts</Message.Header>
                            <br/>
                            MaConfig.tel vous permet de synchroniser des contacts sur tous les téléphones par l' intermédiaire d'un compte externe (Google, etc.).
                            <br />
                            <br />
                            Cliquez sur le bouton suivant « Synchroniser » ou « Désynchroniser » pour lancer la procédure.
                            <br />
                            </Message.Content>
                    </Message>
                </Container>
                <br/>
                <Container textAlign='center'>
                    {!data.book_id > 0 ? <Button  color='teal' onClick={this.props.sync}><Icon name='at' />Synchroniser</Button> : <Button inverted color='orange' onClick={this.props.deSync}><Icon name='at' />Désynchroniser</Button> }
                </Container>


            </Form>
        )
    }
}


export default UadForm;
