import React from "react";

const style404 = {
    color: '#5f5f5f',
    fontSize: '150px',
    textAlign: 'center',
    lineHeight: '1',
    fontWeight: '500',
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
};


const Page404 = () => (
    <div style={style404}>
        404
    </div>
);


export default Page404;