import React, { Component } from 'react';

import { Image, Grid, Segment, Loader } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as DashboardActions from '../../../actions/dashboard';
import { makeCancelable } from '../../../utils';

class Dashboard extends Component {
    state = {
    };

    constructor(props) {
        super(props);
        this.get_dashboard = makeCancelable(this.props.dashboard.get_dashboard(this.props.server_id));
        this.get_dashboard.promise.then( response => {
            this.setState(response);
        });
    };

    componentWillUnmount() {
        if (this.get_dashboard)
            this.get_dashboard.cancel();
    };

    getImageSource () {
        if (this.state.dashboard)
            return 'data:image/png;base64,' + this.state.dashboard;
        return null;
    }

    progressBar () {
        return;
        /*
        if (this.state.sip !== null) {
            if (this.state.sip >= 80)
               return <Progress style={{marginBottom:50}} progress error percent={this.state.sip}>Erreurs enregistrements SIP</Progress>
            if (this.state.sip >= 30)
                return <Progress style={{marginBottom:50}} progress warning percent={this.state.sip}>Erreurs enregistrements SIP</Progress>
            return <Progress style={{marginBottom:50}} progress success percent={this.state.sip}>Erreurs enregistrements SIP</Progress>
        }
        */
    }

    render() {
        return (
            <Segment basic>
                <Grid stackable>
                    <Grid.Column width={16}>
                        <Grid.Row>
                            {this.progressBar()}
                        </Grid.Row>
                        <Grid.Row>
                            <Loader style={{marginTop:40}} active={this.state.dashboard == null} indeterminate>Chargement du Dashboard...</Loader>
                            <Image bordered src={this.getImageSource()}/>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column/>
                </Grid>
            </Segment>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    dashboard: bindActionCreators(DashboardActions, dispatch)
});

export default connect(
    null,
    mapDispatchToProps
)(Dashboard);
