import React, { Component, Fragment } from 'react'
import { Form, Container, Icon, Message, Dimmer, Loader } from 'semantic-ui-react';
import { makeCancelable } from '../utils'

import ErrorMessage from './ErrorMessage';

export default class FormTenant extends Component {
    constructor(props) {
        super(props)
        this.state = {error: null, loading: true, data: {}}
        this.p_get = makeCancelable(this.props.get_server_tenant(this.props.bicom.server_url, this.props.bicom.server_login, this.props.bicom.server_mdp));
        this.p_get.promise.then( payload => {
            this.setState({ loading: false, error: null, data: payload});
            this.props.onValidateStep(true);
        }).catch(error => {
            if (error.isCanceled) return;
            this.setState({ loading: false, error, data: {} });
            this.props.onValidateStep(false);
        });
    }

    componentWillUnmount() {
        if (this.p_get) this.p_get.cancel();
    }
    handleChange=(event, {value, options})=> {
        const res = options.filter(item => item.key === value)
        if (res.length) {
            this.props.onChange('tenant', res[0].item)();
        }
    }
    render() {
/*
[{ "name": "fonia test", "tenantcode": "999", "package_id": 1, "package": "tenant 5", "ext_length": 3, "country_id": 279, "country_code": 33, "id": "2" },
 { "name": "tenant test 2", "tenantcode": "998", "package_id": 1, "package": "tenant 5", "ext_length": 3, "country_id": 279, "country_code": 33, "id": "3" }]
*/
        const {loading, error, data } = this.state;
        if (loading)
            return (<Dimmer active inverted><Loader indeterminate>Recherche...</Loader></Dimmer>);

        if (error)
            return (<ErrorMessage error={error}></ErrorMessage>)

        if (data.type === 'mono')
            return (<Container textAlign="left">
                        <Message icon info>
                            <Icon size="tiny" name='info circle' />
                            <Message.Content>
                                <Message.Header>Serveur mono tenant</Message.Header>
                                <p>Cliquez sur suivant.</p>
                            </Message.Content>
                        </Message>
                    </Container>);

        const tenants = data.tenants.map(item => ({item, key: item.id, value: item.id, text: `${item.tenantcode} : ${item.name}`}))
        const element =  (tenants.length) ? tenants[0]: null;
        if (!this.first) {
            setTimeout(() => {
                this.props.onChange('tenant', element.item)();
            });
        }
        this.first = true;
        return (
            <Fragment>
                <Form.Dropdown
                    fluid
                    selection
                    scrolling
                    disabled={loading}
                    loading={loading}
                    options={tenants}
                    defaultValue={element ? element.value : element}
                    label='Serveur tenant' placeholder='Serveur tenant'
                    onChange={this.handleChange} />
            </Fragment>
        )
    }
}