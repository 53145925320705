import React, { Component, Fragment } from "react";

import {
  Segment,
  Divider,
  Progress,
  Button,
  Container,
  Grid,
  Message,
  Icon,
} from "semantic-ui-react";
import { UI_BUTTON_SIZE } from "../../constants/index";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as PartnerActions from "../../actions/partner";
import * as ServerActions from "../../actions/server";

import AppHeader from "../../Components/AppHeader";
import LounaTable from "../../Components/LounaTable";
import CreateServerModal from "./CreateServerModal";
import ListRemoveConfirm from "../../Components/ListRemoveConfirm";
import EditServerModal from "./EditServerModal";

import { makeCancelable } from "../../utils";

const StatItem = ({ val }) => (
  <Progress
    size="small"
    percent={val}
    style={{ margin: "0" }}
    color="blue"
    progress="percent"
  ></Progress>
);

class PartnerView extends Component {
  state = {
    loading: true,
    data: [],
    load_size: "small",
    createItem: false,
    editItem: null,
  };

  componentWillUnmount() {
    if (this.remove_server) this.remove_server.cancel();
    if (this.get_client_list) this.get_client_list.cancel();
    if (this.get_clients_servers_stats) this.get_clients_servers_stats.cancel();
  }

  handleDisplay = (e, item) => {
    e.stopPropagation();
    this.props.history.push(
      "/client/" + item.client_id + "/phones?server_id=" + item.server_id
    );
  };

  handleProgress = (val) => (element) => {
    const v = element[val];
    return (
      <Fragment>
        <StatItem val={v ? v : 0} />
      </Fragment>
    );
  };

  handleRemove = (item) => (event) => {
    event.stopPropagation();
    this.remove_server = makeCancelable(
      this.props.server.remove_server(item.server_id)
    );
    this.remove_server.promise.then((response) => {
      this.props.partner.get_client_list(this.props.partner_id);
    });
  };

  handleCloseModal = () => {
    this.setState({ editItem: null });
  };
  handleOpenModal = (item) => (e) => {
    e.stopPropagation();
    this.setState({ editItem: item });
  };

  renderServerUrl = (item) => {
    let url = item.server_url;
    if (
      url.toLowerCase().indexOf("http://") === -1 &&
      url.toLowerCase().indexOf("https://") === -1
    )
      url = "https://" + url;
    return (
      <a
        rel="noopener noreferrer"
        href={url}
        alt={item.client_name}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        {url}
      </a>
    );
  };
  handleMenuRow = (d) => {
    let item = d;
    return (
      <Grid stackable columns={1}>
        <Grid.Column textAlign="right">
          <Button
            icon="edit"
            color="blue"
            inverted
            size="mini"
            onClick={this.handleOpenModal(item)}
          ></Button>
          <ListRemoveConfirm
            header="Confirmation de suppression"
            icon="trash alternate"
            label={item.client_name}
            onValidate={this.handleRemove(item)}
          ></ListRemoveConfirm>
        </Grid.Column>
      </Grid>
    );
  };

  handleOpenNewServer = (event) => {
    event.stopPropagation();
    this.setState({ createItem: true });
  };
  handleCloseNewServer = () => {
    this.setState({ createItem: false });
  };
  handleModalDone = () => {
    this.setState({ createItem: false, loading: true });
    this.get_client_list = makeCancelable(
      this.props.partner.get_client_list(this.props.partner_id)
    );
    this.get_client_list.promise.then((response) => {
      this.setState({ loading: false });
    });
  };

  componentDidMount() {
    this.get_client_list = makeCancelable(
      this.props.partner.get_client_list(this.props.partner_id)
    );
    this.get_client_list.promise
      .then((response) => {
        this.setState({ loading: false });
        return this.props.payload.data;
      })
      .then((clientList) => {
        this.get_clients_servers_stats = makeCancelable(
          this.props.partner.get_clients_servers_stats(clientList)
        );
        this.get_clients_servers_stats.promise.then((clientListWithStats) => {
          return;
        });
      });
  }

  render() {
    const { data } = this.props.payload;
    return (
      <Container>
        {this.state.editItem && (
          <EditServerModal
            header={this.state.editItem.client_name}
            item={this.state.editItem}
            onClose={this.handleCloseModal}
          ></EditServerModal>
        )}
        {this.state.createItem && (
          <CreateServerModal
            partner_id={this.props.partner_id}
            onDone={this.handleModalDone}
            onClose={this.handleCloseNewServer}
          ></CreateServerModal>
        )}
        <AppHeader icon="industry" header="Clients"></AppHeader>
        <div className="detailview-content container-fluid">
          {/* table */}
          <Segment>
            <Button
              size={UI_BUTTON_SIZE}
              primary
              onClick={this.handleOpenNewServer}
            >
              <Icon name="plus" />
              Nouvel environnement
            </Button>
            <Button
              size={UI_BUTTON_SIZE}
              primary
              onClick={() =>
                this.props.history.push(
                  "/wizard/tenant/" + this.props.partner_id
                )
              }
            >
              <Icon name="building" />
              Nouveau tenant
            </Button>
            <Divider />
            {this.state.loading ? (
              <Message>
                <Message.Header>Chargement des clients</Message.Header>
                <p>Veuillez patienter, cela peut prendre du temps.</p>
              </Message>
            ) : (
              <LounaTable
                data={data}
                columns={[
                  {
                    Header: "Nom",
                    accessor: "client_name",
                  },
                  {
                    Header: "Serveur Bicom",
                    id: "server_url",
                    maxWidth: 300,
                    accessor: this.renderServerUrl,
                  },
                  {
                    Header: "# Téléphones",
                    accessor: "phones",
                    maxWidth: 150,
                  },
                  {
                    Header: "CPU",
                    id: "cpu",
                    maxWidth: 150,
                    accessor: this.handleProgress("cpu"),
                  },
                  {
                    Header: "Disque",
                    id: "disk",
                    maxWidth: 150,
                    accessor: this.handleProgress("disk"),
                  },
                  {
                    Header: "Mémoire",
                    id: "memory",
                    maxWidth: 150,
                    accessor: this.handleProgress("mem"),
                  },
                  {
                    Header: "Erreurs SIP",
                    id: "sip",
                    maxWidth: 150,
                    accessor: this.handleProgress("sip"),
                  },
                  {
                    Header: "",
                    id: "action",
                    maxWidth: 105,
                    accessor: this.handleMenuRow,
                  },
                ]}
                getTrProps={(state, rowInfo, column) => {
                  if (rowInfo) {
                    return {
                      style: {
                        cursor: "pointer",
                      },
                      onClick: (e) => this.handleDisplay(e, rowInfo.original),
                    };
                  }
                  return {};
                }}
              />
            )}
          </Segment>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  payload: state.partner,
});

const mapDispatchToProps = (dispatch) => ({
  partner: bindActionCreators(PartnerActions, dispatch),
  server: bindActionCreators(ServerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerView);
