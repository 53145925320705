import React, { Component } from 'react';

import { Segment, Button, Form } from 'semantic-ui-react';
import validator from 'validator';

import * as ClientAlertsActions from '../../../actions/clientAlerts';
import { makeCancelable } from '../../../utils';

class OnlineUadAlert extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            newAlert: true,
            uadAlert: {
                email: "",
                percentage: 0,
            }
        };
    };

    componentDidMount() {
        this.get_uad_alert = makeCancelable(
            ClientAlertsActions.get_uad_alert(this.props.server_id)
        );

        this.get_uad_alert.promise
            .then((response) => {
                if (response.percentage && response.email) {
                    this.setState({
                        newAlert: false,
                        uadAlert: {
                            email: response.email,
                            percentage: response.percentage
                        }
                    });
                } else {
                    this.setState({
                        newAlert: true
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    onChangeEmail = (e) => {
        e.persist();

        this.setState((prevState) => ({
            uadAlert: {
                email: e.target.value,
                percentage: prevState.uadAlert.percentage
            }
        }));
    }

    onChangePercentage = (e) => {
        e.persist();

        this.setState((prevState) => ({
            uadAlert: {
                email: prevState.uadAlert.email,
                percentage: e.target.value
            }
        }));
    }

    isEmailValid = (email) => {
        console.log(validator.isEmail(email));
        // return validator.isEmail(email); 
    }

    isPercentageValid = (percentage) => {
    //     console.log(validator.isInt(percentage) && percentage >= 0 && percentage <= 100);
        return validator.isInt(percentage) && percentage >= 0 && percentage <= 100;
    }

    createUadAlert = () => {
        this.create_uad_alert = makeCancelable(
            ClientAlertsActions.create_uad_alert(this.props.server_id, this.state.uadAlert.percentage, this.state.uadAlert.email)
        );

        this.setState({
            loading: true
        })

        this.create_uad_alert.promise
            .then((response) => {
                this.setState({
                    loading: false,
                    newAlert: false
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    updateUadAlert = () => {
        this.update_uad_alert = makeCancelable(
            ClientAlertsActions.update_uad_alert(this.props.server_id, this.state.uadAlert.percentage, this.state.uadAlert.email)
        );

        this.setState({
            loading: true
        })

        this.update_uad_alert.promise
            .then((response) => {
                this.setState({
                    loading: false,
                    newAlert: false
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    componentWillUnmount() {
        if (this.get_uad_alert) this.get_uad_alert.cancel();
        if (this.create_uad_alert) this.create_uad_alert.cancel();
        if (this.update_uad_alert) this.update_uad_alert.cancel();
    }

    render() {
        return (
            <Segment basic style={{ minHeight: '50vh' }}>
                <p>Ici vous avez la possibilité de configurer une alerte qui vous previendra par e-mail lorsque qu'un certain pourcentage de votre parc de téléphones (hors softphones) sera hors-ligne.</p>
                <p><b>Attention : </b> pour mettre en place une alerte, il est nécessaire que vos téléphones aient été déployés via Maconfig.tel.</p>
                <Form>
                    <Form.Input 
                        type='email'
                        label="E-mail"
                        placeholder="email@domaine.fr"
                        required={true}
                        value={this.state.uadAlert.email}
                        onChange={this.onChangeEmail}
                        // error={!this.isEmailValid(this.state.uadAlert.email)}
                    />
                    <Form.Input 
                        type='number'
                        label="Limite d'UADs en ligne (en %)"
                        placeholder="50"
                        required={true}
                        value={this.state.uadAlert.percentage}
                        onChange={this.onChangePercentage}
                        // error={!this.isPercentageValid(this.state.uadAlert.percentage)}
                    />
                    <Button loading={this.state.loading} onClick={this.state.newAlert ? this.createUadAlert : this.updateUadAlert}>Valider</Button>
                </Form>
            </Segment>
        );
    };
}

export default OnlineUadAlert;