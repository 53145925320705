import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Locations from "./Locations"


class RedirectView extends Component {

    render(){
        const { user } = this.props.user;
 
        if (!user.id)
            return <Redirect to={Locations.SignInView.toUrl()} />;
        else if(user.role === 'admin')
            return <Redirect to={Locations.AdminView.toUrl()} />
        else if (user.role === 'partner')
            return <Redirect to={Locations.PartnerView.toUrl({ partner_id: 1})} />
        else if (user.role === 'user')
            return <Redirect to="/client/1/server" />;
    }
}


function mapStateToProps(state, ownProps) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(RedirectView);