import React, { Component, Fragment } from 'react'
import { Form } from 'semantic-ui-react';
import * as Yup from 'yup';


const serverSchema= Yup.string().url().required();

export class FormBicom extends Component {
    state = {server: false}
    handleBlurServer = (e) => {
        this.props.handleChange('bicom', 'server_url', true)(e)
        serverSchema.isValid(e.target.value.trim()).then((valid) => {
            this.setState({server: !valid});
        });
    }

    render() {
        const { values } = this.props;
        return (
            <Fragment>
                <Form.Input autoFocus required fluid label='Nom du serveur Bicom' placeholder='Nom du serveur Bicom' value={values.name} onBlur={this.props.handleChange('bicom', 'name', true)} onChange={this.props.handleChange('bicom', 'name')} />
                <Form.Input error={this.state.server} required fluid label='Adresse du serveur Bicom' placeholder='https://serveur-bicom.com' onBlur={this.handleBlurServer} value={values.server_url} onChange={this.props.handleChange('bicom', 'server_url')} />
                <Form.Input required fluid label='Login Bicom' placeholder='Login Bicom' value={values.server_login} onBlur={this.props.handleChange('bicom', 'server_login', true)} onChange={this.props.handleChange('bicom', 'server_login')} />
                <Form.Input required fluid type='password' label='Mot de passe Bicom' placeholder='Mot de passe Bicom' value={values.server_mdp} onChange={this.props.handleChange('bicom', 'server_mdp')} />
            </Fragment>
        )
    }
}


export class FormBicomEdit extends Component {
    render() {
        const { values } = this.props;
        return (
            <Fragment>
                <Form.Group widths='equal'>
                <Form.Input autoFocus fluid label='Login Bicom' placeholder='Login Bicom' value={values.server_login} onBlur={this.props.handleChange('bicom', 'server_login', true)} onChange={this.props.handleChange('bicom', 'server_login')} />
                <Form.Input fluid type='password' label='Mot de passe Bicom' placeholder='Mot de passe Bicom' value={values.server_mdp} onChange={this.props.handleChange('bicom', 'server_mdp')} />
                </Form.Group>
            </Fragment>
        )
    }
}




export class FormUAD extends Component {
    render() {
        const { values } = this.props;
        return (
            <Fragment>
                <Form.Input autoFocus fluid label='Login UAD' placeholder='Login UAD' defaultValue={values.uad_login} onChange={this.props.handleChange('uad', 'uad_login')} />
                <Form.Input fluid type='password' label='Mot de passe UAD' placeholder='Mot de passe UAD' defaultValue={values.uad_mdp} onChange={this.props.handleChange('uad', 'uad_password')} />
            </Fragment>
        )
    }
}

export class FormUADEdit extends Component {
    render() {
        const { values } = this.props;
        return (
            <Fragment>
                <Form.Group widths='equal'>
                <Form.Input fluid label='Login UAD' placeholder='Login UAD' defaultValue={values.uad_login} onChange={this.props.handleChange('uad', 'uad_login')} />
                <Form.Input fluid type='password' label='Mot de passe UAD' placeholder='Mot de passe UAD' defaultValue={values.uad_password} onChange={this.props.handleChange('uad', 'uad_password')} />
                </Form.Group>
            </Fragment>
        )
    }
}